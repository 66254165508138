import type { TaskI18nContent } from "@/types/init";
import { get } from "lodash";
import { useI18n } from "vue3-i18n";
export function withI18nHelpers() {
  const i18n = useI18n();

  function iterable(key: string): number[] {
    const lookup = get(i18n.messages["en"], key, {});
    return Object.values({ ...lookup }).map((_i, n) => n);
  }

  function fromTaskConfigDefaults(task: any): TaskI18nContent {
    const content = task?.content;

    const defaults: TaskI18nContent = {
      name: "",
      title: "",
      description: "",
      createTaskHeadline: "",
      createTaskSidebar: [],
      singleTaskResultTitleRunning: "",
      singleTaskResultTitleComplete: "",
      singleTaskAction: [],
      singleTaskSidebar: [],
    };
    if (content) {
      const lang = content[i18n.getLocale() as "en"];
      if (lang) {
        return Object.assign({}, defaults, lang);
      }
    }
    return { ...defaults };
  }
  function fromTaskConfig<T extends keyof TaskI18nContent>(key: T, task: any) {
    const config = fromTaskConfigDefaults(task);
    return config[key];
  }

  function getInstance() {
    return i18n;
  }

  return {
    t: i18n.t,
    getLocale: i18n.getLocale,
    setLocale: i18n.setLocale,
    fromTaskConfig,
    getInstance,
    iterable,
  };
}
