<template>

<div class="flex gap-x-2 py-2">
    <div>Suggestion One</div>
    <div>Suggestion Two</div>
    <div>Suggestion Three</div>
</div>
</template>



<script setup lang="ts">

</script>