<template>
  <header
    v-if="user?.tenantId && (!isFullscreen || isOnTenantRoute) && !isInitializing"
    class="h-12 sm:h-14 bg-white dark:bg-slate-800 shadow text-sm sticky top-0 z-[51]"
    :class="{
      'mb-4': !isTenantWithActiveSubAccount && isOnTenantRoute,
    }"
  >
    <div
      class="h-full flex gap-4 justify-between items-center mx-auto px-2"
      :class="v2LayoutEnabled ? 'sm:px-4 md:px-8' : 'max-w-7xl sm:px-4 md:px-6'"
    >
      <div>
        <div class="flex gap-x-2">
          <div v-if="websiteIcon" class="h-5 w-5">
            <img
              :src="websiteIcon"
              alt="icon"
              class="h-full w-full object-cover rounded-full"
            />
          </div>

          <p class="font-medium text-lg leading-tight">{{ user?.companyName }}</p>
        </div>

        <p class="opacity-60 leading-tight text-xs">{{ user?.website }}</p>
      </div>
      <AccountsDropdown :accounts="accounts" />
    </div>
  </header>
  <div
    v-if="user?.tenantId && isTenantWithActiveSubAccount && isOnTenantRoute"
    class="mt-4 mb-4 mx-auto"
    :class="v2LayoutEnabled ? 'px-2 sm:px-4' : 'max-w-[min(95vw,1072px)]'"
  >
    <RouterLink
      to="/tasks"
      class="inline-flex gap-2 items-center hover:text-primary duration-300 font-medium"
    >
      <BackLinkButton as="span" />
      <span>{{ $t("tenantBackToAppLink", { companyName: user.companyName }) }}</span>
    </RouterLink>
  </div>
  <slot
    :isTenant="!!user?.isPartOfTenantAccount"
    :isOnTenantRoute="!!user?.tenantId && !!isOnTenantRoute"
  />
</template>

<script setup lang="ts">
import AccountsDropdown from "@/components/accounts/AccountsDropdown.vue";
import BackLinkButton from "@/components/shared/BackLinkButton.vue";
const { v2LayoutEnabled } = useV2Layout();

const props = defineProps<{
  isAuth: boolean;
  isFullscreen?: boolean;
}>();

const { isInitializing } = useAppInit();
const { user, isTenantWithActiveSubAccount } = useCurrentUser();
const isTenantFeaturesEnabled = computed(() => props.isAuth && !!user.value?.tenantId);
const { data } = useTenantAccountList(isTenantFeaturesEnabled);
const { data: summary } = useAssistantAccountSummaryRequest();
const router = useRouter();
const isOnTenantRoute = computed(() => {
  return router.currentRoute.value.path.startsWith("/tenant");
});

const websiteIcon = computed(() => {
  if (!summary.value) return "";
  return summary.value?.website?.faviconPath;
});
const accounts = computed(() => {
  return (
    data.value?.slice(0, 3).map(account => ({
      label: account.companyName,
      value: account.id,
      description: account.website,
      isActive: account.id === user.value?.activeAccountId,
    })) || []
  );
});
</script>
