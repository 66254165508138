<template>
  <button v-if="toggle" @click="toggleLanguage" :disabled="isPending">
    {{ activeLanguage.short }}
  </button>
  <Menu v-else v-model="activeLanguage" as="div" class="relative inline-block text-left">
    <MenuButton
      :disabled="isLoadingAccount"
      class="text-sm flex items-center"
      :class="
        textClass ||
        'text-slate-500 dark:text-slate-400 hover:text-primary dark:hover:text-primary'
      "
    >
      <Skeletor v-if="isLoadingAccount" shimmer class="h-4 w-20 mt-1.5" />
      <span v-else> {{ shortLabel ? activeLanguage.short : activeLanguage.label }}</span>
    </MenuButton>
    <MenuItems
      as="ul"
      class="absolute divide-y divide-slate-50 dark:divide-slate-700/20 rounded-md bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black/5 dark:ring-slate-700/50 focus:outline-none"
      :class="{
        'origin-top-right right-1/2 translate-x-1/2 w-max':
          !dropdownDirection || dropdownDirection === 'down',
        'origin-bottom-right bottom-full right-1/2 translate-x-1/2 w-max':
          dropdownDirection === 'up',
        'left-full top-1/2 -translate-y-1/2 w-max': dropdownDirection === 'right',
        'right-full top-1/2 -translate-y-1/2 w-max': dropdownDirection === 'left',
      }"
    >
      <MenuItem
        v-for="option in languageOptions"
        as="li"
        v-slot="{ active, close }"
        :key="option.value"
        class=""
        @click="saveLocale(option.value)"
      >
        <button
          class="block p-2 px-4 w-full text-left text-sm"
          :class="{
            'bg-slate-100 dark:bg-slate-900/20':
              !!active && option.value !== user?.locale,
            'bg-primary/20': option.value === user?.locale,
          }"
          @click="close()"
        >
          {{ option.label }}
        </button>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { Skeletor } from "vue-skeletor";
// import SpinIcon from "@/components/icons/SpinIcon.vue";

defineProps<{
  textClass?: string;
  shortLabel?: boolean;
  toggle?: boolean;
  dropdownDirection?: "up" | "down" | "left" | "right";
}>();

const { user, isLoadingAccount } = useCurrentUser();
const { isPending, mutateAsync, isError, error } = useAccountUserUpdate();
const activeLanguage = ref({ label: "English", short: "🇺🇸", value: "en" });

async function saveLocale(locale: string) {
  if (isPending.value) return;
  try {
    await mutateAsync({ locale });
  } catch (e) {
    if (isError.value) {
      console.log(error.value);
    }
  }
}

async function toggleLanguage() {
  if (isPending.value) {
    return;
  }
  const index = languageOptions.findIndex(l => l.value === activeLanguage.value.value);
  if (index === -1) {
    activeLanguage.value = languageOptions[0];
  } else {
    activeLanguage.value = languageOptions[(index + 1) % languageOptions.length];
  }
  await saveLocale(activeLanguage.value.value);
  activeLanguage.value =
    languageOptions.find(l => l.value === user.value?.locale) || languageOptions[0];
}

watchEffect(() => {
  if (user.value) {
    const found = languageOptions.find(
      option => user.value && option.value === user.value.locale
    );
    if (!found) return;
    activeLanguage.value = found;
  }
});
</script>
