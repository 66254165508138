<template>
  <KeepAlive>
    <div
      class="fixed z-[5000] inset-0 bg-[#21a6e6] dark:bg-slate-800 flex flex-col items-center justify-center"
    >
      <div class="mb-4 px-2">
        <AppLogo class="pulse w-48 text-white" />
      </div>
      <div class="text text-center mb-4 text-slate-200 opacity-90 font-medium text-sm">
        <Transition name="fade" mode="out-in">
          <p :key="description">{{ description }}</p>
        </Transition>
      </div>
      <AnimatedDots class="h-12 w-12 text-white" />
    </div>
  </KeepAlive>
</template>

<script setup lang="ts">
import AppLogo from "@/components/logo/AppLogo.vue";
import AnimatedDots from "../shared/AnimatedDots.vue";
defineProps<{
  description?: string;
}>();
usePauseScroll();
</script>
<style scoped>
.pulse {
  animation: pulse 2.8s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
