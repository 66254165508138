<template>
  <div
    v-for="message in messages"
    :key="message.id"
    class="p-2 px-4 rounded-xl max-w-[80%] relative"
    :class="{
      'bg-primary-600 dark:bg-[#2576a2] text-white mr-auto':
        message.sender === 'assistant',
      'bg-white dark:bg-slate-600 ml-auto': message.sender === 'user',
    }"
  >
    <slot>
      <div>
        <p>
          <Skeletor v-if="message.sender === 'assistant'" class="rounded-full h-3 w-56" />
        </p>
        <p>
          <Skeletor class="rounded-full h-3 w-40" />
        </p>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { Skeletor } from "vue-skeletor";

const messages = [
  {
    sender: "user",
    id: 1,
  },
  {
    sender: "assistant",
    id: 2,
  },
  {
    sender: "user",
    id: 3,
  },
  {
    sender: "assistant",
    id: 4,
  },
];
</script>
