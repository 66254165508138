<template>
  <aside
    v-if="helpStore.helpSidebarOpen"
    class="w-[320px] fixed right-2 top-2 shadow-lg h-[calc(100vh-16px)] bg-white dark:bg-surface-800 z-5 flex flex-col rounded-lg z-50"
  >
    <div
      class="flex border-b dark:border-surface-700 p-4 sm:px-6 py-3 justify-between items-center"
    >
      <p class="font-semibold text-lg opacity-80">{{ $t("wordsHelp") }}</p>

      <AppButton
        icon="pi pi-times"
        variant="secondary"
        text
        @click="helpStore.toggleHelpSidebar(false)"
      />
    </div>

    <div class="grow overflow-y-auto relative">
      <div class="absolute inset-0 p-4 sm:p-6 pt-2 sm:pt-2">
        <div v-if="helpStore.hasHistory" class="back">
          <button
            class="py-2 inline-flex gap-2 items-center opacity-80 hover:text-primary duration-300 text-sm font-medium"
            @click="helpStore.goToPreviousTopic"
          >
            <i class="pi pi-arrow-left" />
            <span>{{ $t("wordsPrevious") }}</span>
          </button>
        </div>
        <div
          :key="helpStore.currentContentKey"
          v-motion
          :initial="{ opacity: 0, y: 10 }"
          :enter="{ opacity: 1, y: 0 }"
          :duration="500"
          class=""
        >
          <div
            v-for="(block, blockIndex) in blocks"
            :key="`sidebar-block-${blockIndex}`"
            class="opacity-90 last:pb-6"
            :class="{
              inline: block.inline,
            }"
          >
            <p
              v-if="block.element === 'heading'"
              :class="blockIndex > 0 ? 'mt-4 sm:mt-6' : ''"
              class="font-medium text-lg mb-1"
            >
              {{ block.innerText }}
            </p>
            <p
              v-if="block.element === 'text'"
              class="mb-2"
              :class="{
                inline: block.inline,
              }"
            >
              {{ block.innerText }}
            </p>
            <RouterLink
              v-else-if="block.element === 'link'"
              :to="{
                name: block.name,
                query: block.query,
                params: block.params,
              }"
              class="text-primary hover:text-primary-600 hover:underline duration-200 font-medium"
              >{{ block.innerText }}</RouterLink
            >
            <ul v-if="block.element === 'list'" class="list-disc pl-4">
              <li
                v-for="(item, itemIndex) in block.items"
                :key="`sidebar-item-${itemIndex}`"
              >
                {{ item }}
              </li>
            </ul>
            <button
              v-if="block.element === 'help'"
              class="block text-left text-primary hover:text-primary-600 hover:underline duration-200"
              @click="helpStore.setSidebarContent(block.key)"
            >
              {{ block.innerText }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Feedback - future -->
    <!-- <div
      class="px-4 text-xs border-t dark:border-surface-700 py-3 opacity-90 hover:opacity-100"
    >
      <div v-if="showFeedbackForm">
        Was this helpful? <a class="primary-test-link" @click="sendFeedback(1)">Yes</a> or
        <a class="primary-test-link" @click="sendFeedback(0)">No.</a>
      </div>
      <div v-if="acknowledgeFeedback">Thanks!</div>
    </div> -->
  </aside>
</template>

<script setup lang="ts">
import { RouterLink } from "vue-router";

const helpStore = useAppHelpStore();
const blocks = computed(() => helpStore.currentContent);
</script>
