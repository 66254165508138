import axios, { AxiosError, type AxiosResponse } from "axios";

export const baseAxiosInstance = axios.create();
export const axiosInstance = axios.create({ timeout: 30000 });

export const useGetRequest = axiosInstance.get;
export const usePostRequest = axiosInstance.post;
export const usePatchRequest = axiosInstance.patch;
export const useDeleteRequest = axiosInstance.delete;
export const usePutRequest = axiosInstance.put;

async function errorHelper(error: AxiosError): Promise<AxiosError> {
  const customMessage = (error?.response?.data as any)?.data?.message;
  if (customMessage) {
    return Promise.reject(new Error(customMessage));
  }
  return Promise.reject(new Error(error.message));
}

function responseHelper<
  T extends Record<string, any>,
  A extends AxiosResponse<any> = AxiosResponse<T>,
>(response: A): A {
  if (response?.data?.response) {
    response.data = response.data.response;
  } else if (response?.data?.data) {
    response.data = response.data.data;
  }
  return response;
}

function pickAPI(url: string | undefined, session: typeof currentSessionInformation) {
  if (url && url.indexOf("/account") > -1) {
    return session.appBillingApiUrl;
  }
  return session.appBackendApiUrl;
}

axiosInstance.interceptors.request.use(config => {
  const session = currentSessionInformation;
  config.headers["Authorization"] =
    config.headers["Authorization"] || `Bearer ${session?.appAuthToken}`;
  config.headers["Content-Type"] = config.headers["Content-Type"] || "application/json";
  config.headers["Accept-Language"] =
    config.headers["Accept-Language"] || session.preferredLanguage;
  config.baseURL = config.baseURL || pickAPI(config.url, session);
  if (import.meta.env.MODE !== "test") {
    config.headers["X-Marketing-Assistant-App-Version"] = `web/${session.appVersion}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  r => responseHelper(r),
  e => errorHelper(e)
);
