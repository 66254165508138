import type { ISubscriptionPlan } from "@/stores/billing/types";
import type { UseQueryReturnType } from "@tanstack/vue-query";
import { get } from "lodash-es";

export function useSubscription(
  enabled: boolean | globalThis.Ref<boolean>
): UseQueryReturnType<ISubscriptionPlan | null, Error> {
  const request = useQuery({
    queryKey: ["account", "billing"],
    queryFn: async () => {
      const response = await useGetRequest<ISubscriptionPlan>("/account/billing");
      return get(response, "data", null);
    },
    enabled,
  });
  return request;
}
