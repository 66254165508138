<template>
  <span>
    <span class="duration-300" :class="textClass">
      {{ textStream }}
    </span>
    <span
      v-if="showCaret"
      class="inline-block translate-x-1 duration-300"
      :class="{
        'opacity-0': !typing,
        'animate-[blink-caret_0.95s_step-end_infinite]': typing,
        'h-10 w-1 -mb-1 bg-primary': !caretClass,
        [caretClass || '']: !!caretClass,
      }"
    />
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text: string;
    textClass?: string;
    caretClass?: string;
    autoHideCaret?: boolean;
    duration?: number;
    delay?: number;
    showCaret?: boolean;
  }>(),
  {
    duration: 3 * SECOND,
    autoHideCaret: true,
    delay: 0.5 * SECOND,
  }
);

const { text } = toRefs(props);
const textStream = ref("");
const typing = ref(false);
let typingIntervalId = 0;

function initTyping() {
  if (!text.value) return;
  textStream.value = "";
  const titleLength = text.value.length;
  const interval = props.duration / titleLength;
  let idx = 0;
  typingIntervalId = setInterval(() => {
    if (Math.random() > 0.15 || idx >= titleLength - 1) {
      textStream.value = text.value.slice(0, idx + 1) || "";
    }
    idx++;
    if (idx >= titleLength) {
      if (props.autoHideCaret) stopTypingWithDelay();
      if (typingIntervalId) clearInterval(typingIntervalId);
      typingIntervalId = 0;
      return;
    }
  }, interval);
}

function stopTypingWithDelay() {
  setTimeout(() => {
    typing.value = false;
  }, 3000);
}

watch(text, (_, initial) => {
  if (!initial) return;
  textStream.value = "";
  typing.value = true;
  if (typingIntervalId) clearInterval(typingIntervalId);
  initTyping();
});

onMounted(() => {
  typing.value = true;
  setTimeout(initTyping, props.delay);
});

onBeforeUnmount(() => {
  if (typingIntervalId) clearInterval(typingIntervalId);
  typingIntervalId = 0;
});
</script>

<style>
@keyframes blink-caret {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
