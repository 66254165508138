interface IBanner {
  type?: "error" | "warning" | "success" | "default";
  title: string;
  message: string;
  link?: string;
  id: number;
  linkText?: string;
  dismissible?: boolean;
}

const bannerStore = reactive<IBanner[]>([]);
let lastBannerId = 0;

export const useAppBanner = () => {
  const addBanner = (banner: Omit<IBanner, "id"> & { id?: number }) => {
    bannerStore.push({ ...banner, id: banner.id ?? lastBannerId++ });
  };

  const removeBanner = (id: number) => {
    bannerStore.splice(
      bannerStore.findIndex(banner => banner.id === id),
      1
    );
  };
  const clearAllBanners = () => {
    bannerStore.splice(0, bannerStore.length);
  };

  return {
    bannerStore,
    addBanner,
    removeBanner,
    clearAllBanners,
  };
};
