<template>
  <div>
    <MABanner
      :can-close="!!banner.dismissible"
      v-for="(banner, idx) in last3Banners"
      :key="banner.id"
      :title="banner.title"
      :message="banner.message"
      :type="banner.type"
      :link="banner.link"
      :link-text="banner.linkText"
      :class="idx === bannerStore.length - 1 ? 'mb-4' : 'mb-1'"
      @close="removeBanner(banner.id)"
    />
  </div>
</template>

<script setup lang="ts">
import MABanner from "../primevueComponents/MABanner.vue";

const { bannerStore, removeBanner } = useAppBanner();

const last3Banners = computed(() => {
  return bannerStore.slice(-3, bannerStore.length);
});
</script>
