<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div role="alert">
    <div class="px-4 py-2 text-sm text-white relative rounded" :class="typeColor">
      <div class="flex flex-col sm:flex-row w-full justify-between sm:items-center">
        <div class="flex relative">
          <svg
            v-if="type === 'warning'"
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[5px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            class="w-5 h-4 shrink-0 fill-current opacity-80 mt-[5px] mr-3"
            v-else-if="type === 'error' || type === 'error-alt'"
            :class="{
              'text-red-500': type === 'error-alt',
            }"
          >
            <path
              fill-rule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-else-if="type === 'success'"
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[5px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
            />
          </svg>
          <svg
            v-else
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[5px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
            />
          </svg>
          <slot class="">
            <div class="text-sm">
              <div
                class="font-semibold mr-2 empty:hidden text-lg"
                :class="{
                  'text-red-500': type === 'error-alt',
                }"
              >
                {{ title }}
              </div>
              <div class="empty:hidden sm:w-[70%] md:w-[95%]">
                {{ message }}
              </div>
            </div>
          </slot>
        </div>
        <div
          v-if="canClose || link"
          class="flex gap-2 mt-2 sm:mt-0 justify-end items-center"
        >
          <RouterLink
            v-if="link"
            :to="link"
            class="btn btn-sm bg-white/30 !text-white !rounded font-semibold"
            :class="{
              'text-red-500 backdrop-blur-sm': type === 'error' || type === 'error-alt',
              'text-green-500 ': type === 'success',
              'text-yellow-500 ': type === 'warning',
              'text-primary': !type || type === 'default',
            }"
            >{{ linkText || $t("wordsDetails") }}</RouterLink
          >
          <button
            v-if="canClose"
            class="opacity-70 hover:opacity-80 ml-3 closeBtn mb-auto"
            @click="close"
          >
            <div class="sr-only">{{ $t("buttons.close") }}</div>
            <svg class="w-4 h-4 fill-current">
              <path
                d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    canClose: boolean;
    type?: "warning" | "error" | "error-alt" | "success" | "default";
    title: string;
    message?: string;
    link?: string;
    linkText?: string;
  }>(),
  {
    type: "default",
  }
);
const emit = defineEmits(["close", "close-toast"]);

const typeColor = computed(() => {
  switch (props.type) {
    case "warning":
      return "bg-amber-500";
    case "error":
      return "bg-red-500";
    case "error-alt":
      return "bg-white dark:bg-slate-800 !text-slate-600 dark:!text-slate-100 border border-slate-200/40 dark:border-slate-700/40 shadow-sm";
    case "success":
      return "bg-green-500";
    case "default":
    default:
      return "bg-primary-500 dark:bg-primary-600";
  }
});

function close() {
  if (props?.canClose) {
    emit("close");
    emit("close-toast");
  }
}
</script>

<style scoped>
.closeBtn {
  margin-top: 3px;
}

@media (max-width: 640px) {
  .closeBtn {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
  }
}
</style>
