export const V2_LAYOUT_EVENT_NAME = "v2LayoutChange";
const v2LayoutEvent = new Event(V2_LAYOUT_EVENT_NAME, {
  bubbles: false,
  cancelable: true,
});

function v2Layout() {
  let initialized = false;
  const hasV2Feature = hasFeatureFlag("v2Wrapper");

  if (!hasV2Feature) {
    localStorage.removeItem("v2LayoutEnabled");
  }

  const _v2LayoutEnabled = hasV2Feature
    ? useLocalStorage<boolean>("v2LayoutEnabled", false)
    : ref(false);

  const v2LayoutEnabled = computed(() => _v2LayoutEnabled.value);

  const _toggleV2Layout = hasV2Feature
    ? useToggle(_v2LayoutEnabled)
    : (a?: boolean) => !!a;

  function toggleV2Layout(value?: boolean) {
    if (!hasV2Feature) return;
    _toggleV2Layout(typeof value === "boolean" ? value : !v2LayoutEnabled.value);
    if (v2LayoutEnabled.value) {
      hideScrollbar();
    } else showScrollbar();
    document.dispatchEvent(v2LayoutEvent);
  }

  onMounted(() => {
    if (initialized) return;
    if (v2LayoutEnabled.value) hideScrollbar();
    else showScrollbar();
    initialized = true;
  });

  function hideScrollbar() {
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.maxHeight = "100vh";
    document.body.style.overflow = "hidden";
    document.body.classList.add("no-scrollbar");
    document.documentElement.classList.add("no-scrollbar");
  }
  function showScrollbar() {
    document.body.classList.remove("no-scrollbar");
    document.documentElement.classList.remove("no-scrollbar");
    document.documentElement.style.overflow = "auto";
    document.documentElement.style.maxHeight = "none";
    document.body.style.overflow = "auto";
  }

  return { v2LayoutEnabled, toggleV2Layout, hasV2Feature };
}

export const useV2Layout = createSharedComposable(v2Layout);

export function useHiddenV2Input(auto = true) {
  const v2InputStore = useV2InputStore();

  function hideInputs() {
    v2InputStore.currentStateHelpers.hideInputs();
    v2InputStore.toggleBottomSectionHeight(0);
  }
  function showInputs() {
    v2InputStore.currentStateHelpers.showInputs();
    v2InputStore.toggleBottomSectionHeight();
  }

  onBeforeMount(() => {
    if (auto) hideInputs();
  });
  onBeforeUnmount(() => {
    if (auto) showInputs();
  });

  return { hideInputs, showInputs };
}

export const useV2Menu = createSharedComposable(() => {
  const [isMenuOpen, toggleMenuOpen] = useToggle();

  return { isMenuOpen, toggleMenuOpen };
});
