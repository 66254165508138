export function assert(
  condition: any,
  message?: string | Error | undefined
): asserts condition {
  if (
    condition === undefined ||
    condition === null ||
    condition === false ||
    !condition
  ) {
    if (typeof message === "string") {
      throw new Error(message);
    } else if (message instanceof Error) {
      throw message;
    } else {
      throw new Error(`Assertion error`);
    }
  }
}

export function getLastArrayItem<T>(items: T[]): T | null {
  if (!Array.isArray(items)) return null;
  return items[items.length - 1] || null;
}
