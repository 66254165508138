const isInitializing = ref(false);

export function useAppInit() {
  function startInitializing() {
    isInitializing.value = true;
  }

  function stopInitializing() {
    isInitializing.value = false;
  }

  return {
    isInitializing: readonly(isInitializing),
    startInitializing,
    stopInitializing,
  };
}
