<template>
  <div class="flex flex-col items-center justify-center p-4 h-full">
    <p
      v-motion
      class="font-bold text-3xl sm:text-4xl md:text-5xl bg-gradient-to-r from-blue-600 dark:via-primary to-primary-400 dark:to-primary-400 bg-clip-text text-transparent opacity-0 duration-1000 pb-1"
      :initial="{ opacity: 0, y: 10 }"
      :enter="{ opacity: 1, y: 0 }"
    >
      {{ $t(timeOfDay) }}
    </p>
    <div class="relative font-semibold text-lg mb-6">
      <p class="opacity-0 pointer-events-none">
        {{ $t("howCanBotHelpToday") }}
      </p>
      <p class="absolute top-0 left-0 text-slate-800/30 dark:text-slate-200/30">
        {{ $t("howCanBotHelpToday") }}
      </p>
      <p class="absolute top-0 left-0">
        <StreamedText
          auto-hide-caret
          :text="$t('howCanBotHelpToday')"
          :duration="1.8 * SECOND"
          :delay="SECOND"
        />
      </p>
    </div>

    <!-- <ConversationStarterSuggestions /> -->
  </div>
</template>

<script setup lang="ts">
import StreamedText from "@/components/shared/StreamedText.vue";
// import ConversationStarterSuggestions from "./ConversationStarterSuggestions.vue";

const timeOfDay = computed(() => getTimeOfDay());

function getTimeOfDay() {
  const hour = new Date().getHours();
  if (hour < 12) {
    return "greetingGoodMorning";
  } else if (hour < 17) {
    return "greetingGoodAfternoon";
  } else {
    return "greetingGoodEvening";
  }
}
</script>
