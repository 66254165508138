export function defaultInputStyles({ invalid, disabled, size }) {
  return [
    "m-0",
    "w-full",
    "rounded-md",
    "shadow-sm",
    "appearance-none",
    {
      "px-2 py-1 text-lg sm:text-md": size === "small",
      "px-4 py-3": size === "large",
      "px-4 py-2.5 min-w-[2rem]": !["small", "large"].includes(size),
    },

    "font-sans leading-6",
    "text-surface-900 dark:text-surface-0",
    "placeholder:text-surface-400 dark:placeholder:text-surface-500",
    "bg-surface-50 dark:bg-surface-900",
    "border-surface-300 dark:border-surface-600",

    "ring-1 ring-inset ring-offset-0",
    { "ring-surface-200 dark:ring-surface-700": !invalid },

    // Invalid State
    { "ring-rose-400 dark:ring-red-400": invalid },

    {
      "outline-none focus:ring-primary-500 dark:focus:ring-primary-400": !disabled,
      "opacity-60 select-none pointer-events-none cursor-default": disabled,
    },
  ];
}
