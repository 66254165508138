<template>
  <div>
    <FileComponent ref="fileManager" @uploadSuccess="onUploadSuccess" :showButton="false">
      <form @submit.prevent="submitForm" class="w-full relative">
        <div
          v-if="formData.images && formData.images.length"
          class="bg-slate-100 px-4 py-6 rounded-xl mb-2"
        >
          <div class="flex gap-x-2">
            <img
              v-for="(image, index) in formData.images"
              :key="`image-${index}`"
              class="w-52"
              :src="image"
            />
          </div>
        </div>
        <div
          v-if="fileManager?.uploader?.isUploading.value"
          class="flex items-center gap-x-2 font-medium text-slate-400 mb-2"
        >
          <SpinIcon v-if="!loading" class="w-3 h-3" />
          <div class="text-sm">Uploading Image</div>
        </div>

        <div class="flex w-full justify-between items-end">
          <div class="block w-full">
            <textarea
              v-model="formData.content"
              :disabled="loading"
              placeholder="How can I help?"
              class="w-full text-xl resize-none"
              :rows="rowCount"
            ></textarea>
          </div>
          <AppButton
            :disabled="disabled || loading || fileManager?.uploader?.isUploading.value"
            @click="submitForm"
            :label="$t('buttons.save')"
            :loading="loading"
          />
        </div>
        {{}}
        <div
          v-if="fileManager?.isOverDropZone"
          class="bg-blue-50 bg-opacity-25 absolute w-full h-full border-4 border-dashed border-blue-50 top-0 left-0 -z-10 scale-105 p-20"
        ></div>
      </form>
    </FileComponent>
  </div>
</template>

<script setup lang="ts">
import type { ContentInput } from "@/types/formData";
import SpinIcon from "@/components/icons/SpinIcon.vue";
import FileComponent from "@/components/upload/FileComponent.vue";
import { isPast } from "date-fns";
const fileManager = ref<any>(null);

defineProps<{
  loading?: boolean;
}>();

const formData = reactive<ContentInput>({
  content: "",
  images: [],
});

const emit = defineEmits<{
  (e: "submit", data: ContentInput): void;
}>();

const uploaded = ref<any>(null);

function onUploadSuccess(file: any) {
  uploaded.value = file;
  formData.images!.push(`${getEnv("VITE_FILES_DOMAIN")}/${file.state.data.path}`);
}

const isInvalidDate = computed(
  () =>
    formData.scheduleTime &&
    formData.scheduleTime.type === "date" &&
    isPast(new Date(`${formData?.scheduleTime?.value}.000Z`))
);

const hasChanges = computed(() => {
  const diff = objectDifference(formData, {});
  return Object.keys(diff.updates).length > 0;
});

const rowCount = computed(() => {
  const min = 1;
  const breaks = formData?.content?.split("\n")?.length || 0;
  if (min > breaks) {
    return min;
  }
  return breaks;
});

const disabled = computed(() => {
  if (typeof formData.url === "string") {
    return (
      !hasChanges.value ||
      isInvalidDate.value ||
      (!formData.content && !formData.html && !isValidUrl(formData.url))
    );
  }
  return isInvalidDate.value;
});

function submitForm() {
  if (!disabled.value) {
    emit("submit", Object.assign(formData, { html: formData.content }));
  }
}
</script>
