import type { AssistantWebsiteResponse } from "@/types/assistant";

export function getAssistantSingleCompetitorQueryKey(id: string) {
  return ["assistant-data", "competitors", id];
}

export function useAssistantSingleCompetitorRequest(id: string) {
  const query = useQuery({
    queryKey: getAssistantSingleCompetitorQueryKey(id),
    queryFn: async () => {
      if (!id || typeof id !== "string") throw new Error("Missing id");
      const response = await useGetRequest<AssistantWebsiteResponse>("/account/data", {
        params: {
          itemId: id,
        },
      });
      return response?.data || null;
    },
    staleTime: 3 * MINUTE,
  });
  return query;
}
