<template>
  <div class="">
    <div class="loader w-8"></div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
/* .loader {
  width: 85px;
  height: 35px;
  --g1: conic-gradient(from 90deg at 3px 3px, #0000 90deg, #000 0);
  --g2: conic-gradient(from -90deg at 22px 22px, #0000 90deg, #000 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  animation: l6 1s infinite alternate;
}
@keyframes l6 {
  0% {
    background-position:
      0 50%,
      50% 50%,
      100% 50%;
  }
  20% {
    background-position:
      0 0,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0 100%,
      50% 0,
      100% 50%;
  }
  60% {
    background-position:
      0 50%,
      50% 100%,
      100% 0;
  }
  80% {
    background-position:
      0 50%,
      50% 50%,
      100% 100%;
  }
  100% {
    background-position:
      0 50%,
      50% 50%,
      100% 50%;
  }
} */

.loader {
  aspect-ratio: 2;
  --gradient: no-repeat
    radial-gradient(circle closest-side, currentColor 90%, transparent);
  background:
    var(--gradient) 0% 50%,
    var(--gradient) 50% 50%,
    var(--gradient) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: bounce-bg 1s infinite linear;
}
@keyframes bounce-bg {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}
</style>
