import type { RouteRecordRaw } from "vue-router";

export const scheduledRoutes: RouteRecordRaw[] = [
  {
    name: "scheduled-activities",
    path: "",
    component: () => import("../views/ScheduledActivities.vue"),
  },
  {
    path: ":id",
    name: "single-scheduled-activity",
    component: () => import("@/views/SingleTaskScheduled.vue"),
    meta: {
      titleTag: "Scheduled Activity | Marketing Assistant",
    },
  },
];
