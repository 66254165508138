<template>
  <div
    role="button"
    class="w-full px-4 py-2.5 mb-3 duration-200 rounded-lg"
    :class="{
      'bg-primary/10 text-primary border border-primary/40': active,
      'hover:bg-slate-100 dark:hover:bg-slate-900/10 border dark:border-slate-700':
        !active,
    }"
    @click="emit('setActiveId', item.id)"
  >
    <div class="truncate font-medium text-sm">
      <p class="font-semibold truncate">{{ item?.messages[0].content }}</p>
      <p v-if="item?.messages[1]" class="truncate">{{ item?.messages[1].content }}</p>
      <span class="text-xs">{{ date }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RecentHeyConversationListItem } from "@/types/v2";

const props = defineProps<{
  item: RecentHeyConversationListItem;
  active?: boolean;
}>();

const emit = defineEmits<{
  (e: "setActiveId", id: string): void;
}>();

const date = useTimeAgo(props.item.modified);
</script>
