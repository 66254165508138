<template>
  <AuthLoading
    v-if="loadingState.shown"
    :description="loadingState.message"
    :class="loadingState.shown ? 'pointer-events-none' : ''"
  />
  <AuthenticationContext ref="authRef">
    <RouterView />
    <PwaUpdateModal v-if="isRevealed" @confirm="confirm" @cancel="cancel" />
  </AuthenticationContext>
</template>

<script lang="ts" setup>
import { debounce } from "lodash-es";
import AuthenticationContext from "./context/AuthenticationContext.vue";
import AuthLoading from "@/components/auth/AuthLoading.vue";
import PwaUpdateModal from "@/components/pwa/PwaUpdateModal.vue";
type LoadingState = {
  authLoadingState: {
    shown: boolean;
    message: string | undefined;
  };
};

const authRef = ref<LoadingState>();
const router = useRouter();
const { cancel, confirm, isRevealed } = usePwaDialogHandler();

const loadingState = computed(() => {
  if (authRef.value?.authLoadingState.shown) {
    return authRef.value?.authLoadingState;
  }
  return {
    shown: false,
    message: undefined,
  };
});

watchEffect(() => {
  const { meta = {} } = router.currentRoute.value;
  const { titleTag = "Marketing Assistant" } = meta;
  if (titleTag) {
    document.title = `${titleTag}` as string;
  }
});
onMounted(() => {
  if (!isDESKTOP) return;
  if (!window.electronAPI?.onUrlOpen) return;
  window.electronAPI?.onUrlOpen((_url: string) => {
    if (typeof _url !== "string") return;
    const url = _url.replace("ma://", "");
    const newUrl = url.startsWith("/") ? url : `/${url}`;
    router.push(newUrl);
  });
});

onBeforeMount(() => {
  if (typeof window === "undefined") return;
  const _r = window.ResizeObserver;
  (window as any).ResizeObserver = class ResizeObserver extends _r {
    constructor(callback: (...args: any[]) => void) {
      callback = debounce(callback, 20);
      super(callback);
    }
  };
});
</script>
