<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.4"
      d="M8.927 2.5h6.146c1.824 0 3.293 0 4.45.155c1.2.162 2.21.507 3.012 1.31c.803.802 1.148 1.813 1.31 3.013C24 8.134 24 9.603 24 11.427v1.146c0 1.824 0 3.293-.155 4.45c-.162 1.2-.507 2.21-1.31 3.012c-.802.803-1.812 1.148-3.013 1.31c-1.156.155-2.625.155-4.449.155H8.927c-1.824 0-3.293 0-4.45-.155c-1.2-.162-2.21-.507-3.013-1.31c-.802-.802-1.147-1.812-1.309-3.013C0 15.866 0 14.397 0 12.573v-1.146c0-1.824 0-3.293.155-4.45c.162-1.2.507-2.21 1.31-3.013c.802-.802 1.813-1.147 3.013-1.309C5.634 2.5 7.103 2.5 8.927 2.5M4.744 4.638c-.978.131-1.496.372-1.865.74c-.37.37-.61.888-.741 1.866C2.002 8.251 2 9.586 2 11.5v1c0 1.914.002 3.249.138 4.256c.131.978.372 1.496.74 1.865c.37.37.888.61 1.866.742c1.007.135 2.342.137 4.256.137h6c1.914 0 3.249-.002 4.256-.137c.978-.132 1.496-.373 1.865-.742c.37-.369.61-.887.742-1.865c.135-1.007.137-2.342.137-4.256v-1c0-1.914-.002-3.249-.137-4.256c-.132-.978-.373-1.496-.742-1.865c-.369-.37-.887-.61-1.865-.741C18.249 4.502 16.914 4.5 15 4.5H9c-1.914 0-3.249.002-4.256.138m4.057 2.326l8.695 5.037l-8.695 5.036zm2 3.47v3.134L13.506 12z"
    ></path>
  </svg>
</template>

<script lang="ts">
export default {
  name: "TdesignLogoYoutube",
};
</script>
