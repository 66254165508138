<template>
  <div
    class="fixed bottom-0 sm:bottom-[unset] sm:top-0 w-full sm:w-16 md:w-24 shrink-0 grow-0 z-50 no-scrollbar border-t sm:border-r border-slate-200 dark:border-slate-700 dark:sm:border-slate-800 bg-slate-100 dark:bg-slate-800 sm:bg-transparent dark:sm:bg-transparent"
    :class="v2LayoutEnabled ? 'sm:h-full' : 'sm:h-screen'"
  >
    <div
      class="sm:h-full flex gap-4 sm:gap-0 flex-row-reverse sm:flex-col justify-between after:w-16 sm:after:w-auto sm:after:flex-1 after:mt-auto after:pointer-events-none w-full"
    >
      <div class="sm:flex-1 w-1 min-[420px]:w-14 sm:w-auto">
        <div
          v-if="router.currentRoute.value.fullPath !== '/'"
          class="flex justify-end sm:justify-center mr-6 sm:mr-0 items-center my-4"
          :class="isMobile ? 'opacity-0 pointer-events-none' : ''"
        >
          <DropdownProfile
            :avatar-url="user?.picture"
            @sign-out="doLogout"
            :align="isMobile ? 'right' : 'left'"
            class=""
            :hide-settings="!billingStore.hasBypass && !userSubscriptionPriceId"
          />
        </div>
      </div>
      <div
        class="flex-1 grow flex items-center"
        :class="{
          'opacity-0 pointer-events-none':
            !billingStore.hasBypass && !userSubscriptionPriceId,
        }"
      >
        <nav class="w-full" @click="maybeCloseV2Chat">
          <ul
            class="flex justify-center items-center sm:block space-x-4 min-[360px]:space-x-6 sm:space-y-4 sm:space-x-0 mx-auto sm:mx-0 duration-500"
            :class="
              isV2MenuOpen && isMobile ? 'opacity-0 pointer-events-none' : 'opacity-100'
            "
          >
            <template v-if="isInitializing">
              <li
                v-for="i of 5"
                :key="i"
                class="sm:py-1 h-full sm:h-[unset] flex items-center justify-center"
              >
                <Skeletor :circle="false" class="h-9 w-9 sm:h-8 sm:w-8 rounded-md" />
              </li>
            </template>
            <template v-else>
              <li class="sm:py-2 h-full sm:h-[unset]" :data-testid="$t('suggestions')">
                <SidebarLink :to="{ name: 'suggestions' }" :label="$t('suggestions')">
                  <template #icon>
                    <div class="relative flex-shrink-0">
                      <span
                        v-if="hasSuggestions"
                        class="absolute inline-block -left-2 -top-1 bg-yellow-500 text-white px-2 py-1.5 rounded-full text-xs"
                      >
                        <span class="block h-1 w-1 bg-white rounded-full"></span>
                      </span>
                      <BulbIcon class="h-9 w-9 sm:h-8 sm:w-8" />
                    </div>
                  </template>
                </SidebarLink>
              </li>
              <li
                class="sm:py-2 h-full sm:h-[unset] -mb-1.5 sm:mb-0"
                :data-testid="$t('wordsTasks')"
              >
                <SidebarLink
                  :to="{ name: 'task-list', params: { status: 'recent' } }"
                  :label="$t('wordsTasks')"
                  :is-active="isTaskRoute"
                >
                  <template #icon>
                    <TaskIcon class="h-9 w-8 sm:h-8 sm:w-8 flex-shrink-0" />
                  </template>
                </SidebarLink>
              </li>
              <li class="sm:py-2 h-full sm:h-[unset]" :data-testid="$t('wordsScheduled')">
                <SidebarLink
                  :to="{ name: 'scheduled-activities' }"
                  :label="$t('wordsScheduled')"
                >
                  <template #icon>
                    <TimerIcon class="h-10 w-10 sm:h-8 sm:w-8 flex-shrink-0" />
                  </template>
                </SidebarLink>
              </li>
              <li class="sm:py-2 h-full sm:h-[unset]" :data-testid="$t('wordsAssistant')">
                <SidebarLink :to="{ name: 'brain' }" :label="$t('wordsAssistant')">
                  <template #icon>
                    <CpuIcon class="h-9 w-9 sm:h-8 sm:w-8 flex-shrink-0" />
                  </template>
                </SidebarLink>
              </li>
              <li
                v-if="hasFeatureFlag('messagesUi') && hasEmailIntegration"
                class="sm:py-2"
              >
                <SidebarLink :to="{ name: 'messages' }" :label="$t('buttons.chat')">
                  <template #icon>
                    <ChatIcon class="h-9 w-9 sm:h-8 sm:w-8 flex-shrink-0" />
                  </template>
                </SidebarLink>
              </li>
              <li class="sm:py-2" :data-testid="$t('settings.settings')">
                <SidebarLink :to="{ name: 'Settings' }" :label="$t('settings.settings')">
                  <template #icon>
                    <SettingsIcon class="h-10 w-10 sm:h-8 sm:w-8 flex-shrink-0" />
                  </template>
                </SidebarLink>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TimerIcon from "@/components/icons/TimerIcon.vue";
import DropdownProfile from "./DropdownProfile.vue";
import CpuIcon from "@/components/icons/CpuIcon.vue";
import BulbIcon from "@/components/icons/BulbIcon.vue";
import { Skeletor } from "vue-skeletor";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import ChatIcon from "@/components/icons/ChatIcon.vue";
import TaskIcon from "@/components/icons/TaskIcon.vue";
import SidebarLink from "@/components/layout/SidebarLink.vue";

const { user, hasIntegration } = useCurrentUser();
const router = useRouter();
const billingStore = useBillingStore();
const { userSubscriptionPriceId } = toRefs(currentSessionInformation);
const v2ResponseStore = useV2ResponseStore();
const { isMenuOpen: isV2MenuOpen } = useV2Menu();

async function doLogout() {
  await router.push("/logout");
}

const { isInitializing } = useAppInit();
const suggestionsRequestShouldWait = computed(() => !user.value);
const { data: suggestions } = useAssistantSuggestionsRequest(
  ref(""),
  suggestionsRequestShouldWait
);

const hasEmailIntegration = computed(() => hasIntegration("email"));

const currentRoute = computed(() => router.currentRoute.value);
const { v2LayoutEnabled } = useV2Layout();
const isTaskRoute = computed(() => currentRoute.value.path.startsWith("/task"));
const hasSuggestions = computed(() => !!suggestions.value?.length);

function maybeCloseV2Chat() {
  if (!v2LayoutEnabled.value) return;
  v2ResponseStore.hideResponseOverlay();
}
</script>
