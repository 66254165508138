import type { ScheduleTime } from "@/types/task";
import { format, formatDuration, intervalToDuration, type Locale } from "date-fns";
import { type I18nInstance } from "vue3-i18n";
import { parseExpression } from "cron-parser";

interface DurationOptions {
  format?: string[];
  zero?: boolean;
  delimiter?: string;
  locale?: Locale;
}

export function getReadableDuration(milliseconds: number, options?: DurationOptions) {
  //2 years 11 months 3 days 12 hours 45 minutes and 22 seconds
  if (!milliseconds) return "0s";
  const time = intervalToDuration({ start: 0, end: milliseconds });
  return formatDuration(time, options);
}

export function getDurationAsTimeStamp(milliseconds: number) {
  if (!milliseconds) return "0:00";
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  let hours = 0;
  if (duration.days) hours += duration.days * 24;
  if (duration.weeks) hours += duration.weeks * 24 * 7;
  if (duration.months) hours += duration.months * 24 * 30;
  if (duration.years) hours += duration.years * 24 * 365;
  let time = "";
  // const time = `${hours}:${duration.minutes}:${duration.seconds}`;
  if (hours) time += `${hours}:`.padStart(2, "0");
  if (time || duration.minutes) time += `${duration.minutes}:`.padStart(2, "0");
  if (!time) time = "0:";
  time += `${duration.seconds}`.padStart(2, "0");
  return time || "0:00";
}

/**
 * Format short duration in human readable format. 1m, 2mo, 3y, 4d, etc
 * @param _date date or timestamp
 * @returns string
 */
export function formatShortDuration(_date: Date | string | number) {
  if (!_date) return "";
  const date = new Date(_date);
  const difference = date.getTime() - new Date().getTime();
  const duration = intervalToDuration({ start: 0, end: difference });
  const { days, hours, minutes, months, years, seconds, weeks } = duration;
  if (years) return `${years}y`;
  if (months) return `${months}mo`;
  if (weeks) return `${weeks}w`;
  if (days) return `${days}d`;
  if (hours) return `${hours}h`;
  if (minutes) return `${minutes}m`;
  if (seconds) return `${seconds}s`;
  return "";
}

export function formatLongDate(date: string | number | Date) {
  const fmt = "PPPPp";
  if (!date) return "N/A";
  return format(new Date(date), fmt);
}

export function formatDateAndTime(
  date: string | number | Date,
  customFormat = "MMM d, yyyy | p"
) {
  if (!date) return "N/A";
  if (typeof date === "string" && isNaN(Date.parse(date))) return "N/A";
  return format(new Date(date), customFormat);
}

export function formatScheduleTimeMessage(schedule: ScheduleTime, i18n: I18nInstance) {
  if (!schedule) return "";
  let value = "";
  let suffix = "";
  switch (schedule.type) {
    case "date":
      value = formatLongDate(`${schedule.value}`);
      suffix = i18n.t("itWillRunOn", { date: value });
      break;
    case "days":
    case "hours":
    case "minutes":
      value = `${schedule.value} ${i18n.t(`${schedule.type}`)}`;
      suffix = i18n.t("itWillRunEvery", { interval: value });
      break;
    case "cron":
      value = `${schedule.value}`;
      suffix = i18n.t("nextRun", { date: value ? formatLongDate(value) : "N/A" });
      break;
  }
  if (!value) return "";

  return `${i18n.t("taskScheduledSuccessMessage")}. ${suffix}`;
}

export function getNextCronTime(cron: string) {
  if (!cron) return "";
  try {
    const interval = parseExpression(cron);
    return interval.next().toISOString();
  } catch {
    return "";
  }
}
