import type { IntegrationTypes, UserAccountDetails } from "@/types/account";
import { useAuth0 } from "@auth0/auth0-vue";
import { useI18n } from "vue3-i18n";
import { version as appVersion } from "../../package.json";

export function useCurrentUser() {
  const auth0 = useAuth0();
  const i18n = useI18n();
  const isDESKTOP = computed(() => import.meta.env.VITE_DESKTOP_MODE);
  const enabled = computed(() => !!currentSessionInformation.appAuthToken);

  const request = useQuery({
    queryKey: ["account"],
    queryFn: async () => {
      const response = await useGetRequest<UserAccountDetails>(`/account`);
      return response?.data || null;
    },
    initialData: null,
    enabled: enabled,
    staleTime: 3 * 60 * 1000,
  });

  const isTenantWithoutAccounts = computed(() => {
    if (!request.data.value?.isPartOfTenantAccount) return false;
    return (
      request.data.value.tenantId &&
      request.data.value.accounts.length === 1 &&
      request.data.value.accounts[0] === request.data.value.activeAccountId
    );
  });

  const isTenantWithActiveSubAccount = computed(() => {
    if (!request.data.value?.isPartOfTenantAccount) return false;
    return (
      !!request.data.value.activeAccountId &&
      request.data.value.activeAccountId !== request.data.value.tenantId
    );
  });

  function hasIntegration(type: IntegrationTypes) {
    const integrations = request.data.value?.integrations || [];
    if (!integrations.length) return false;
    const integration = integrations.find(i => i.id === type);
    if (!integration) return false;
    return integration.enabled;
  }

  watchEffect(() => {
    if (request.data.value?.locale) {
      i18n.setLocale(request.data.value?.locale);
    }
  });

  const isAuthenticated = computed(() => {
    if (import.meta.env.MODE === "local-test") {
      return true;
    }
    return isDESKTOP.value || auth0.isAuthenticated.value;
  });

  const idTokenClaimsLocalApi = ref<Record<string, any>>({});

  const idTokenClaims = computed(() => {
    if (import.meta.env.MODE === "local-test") {
      return idTokenClaimsLocalApi.value;
    }
    return isDESKTOP.value ? {} : auth0.idTokenClaims.value;
  });

  return {
    user: computed(() => request.data.value),
    refetchUser: request.refetch,
    logout: auth0.logout,
    hasIntegration,
    isTenantWithoutAccounts,
    isTenantWithActiveSubAccount,
    loginWithRedirect: auth0.loginWithRedirect,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    loadingAuthentication: computed(() => auth0.isLoading.value),
    isAuthenticated: isAuthenticated,
    isAuthenticationError: computed(() => auth0.error.value),
    idTokenClaims: idTokenClaims,
    isFetchingAccount: computed(() => request.isFetching.value),
    isLoadingAccount: computed(() => request.isLoading.value),
    isInitialLoadingComplete: computed(() => request.isFetched.value),
    auth0User: computed(() => auth0.user.value),
  };
}

/**
 * Current session information
 * !!!! DO NOT MODIFY !!!!
 */
export const currentSessionInformation = reactive({
  appVersion,
  appBackendApiUrl: "",
  appAuthToken: "",
  appWebsocketApiUrl: "",
  appBillingApiUrl: "",
  userStripeId: "",
  appUserId: "",
  appAccountId: "",
  userSubscriptionPlanId: "",
  userSubscriptionPriceId: "",
  onboardingRequired: false,
  initializingSession: false,
  preferredLanguage: "en",
});

export const appAuthToken = ref("");

export const isLoggingOut = ref(false);

export const facebookLoggedIn = ref(false);

export function setCurrentSessionClaims(claims: Auth0Profile | Record<string, string>) {
  if (!Object.keys(claims).length) return;
  currentSessionInformation.appAuthToken = appAuthToken.value;
  currentSessionInformation.appAccountId = claims["BotCore/accountId"];
  currentSessionInformation.appUserId = claims["BotCore/userId"];
  currentSessionInformation.userStripeId = claims["BotCore/stripeId"];
  currentSessionInformation.appWebsocketApiUrl = claims["BotCore/websocketUrl"];
  currentSessionInformation.appBillingApiUrl = claims["BotCore/apiAlternateUrl"];
  currentSessionInformation.appBackendApiUrl = claims["BotCore/apiUrl"];
  currentSessionInformation.userSubscriptionPriceId = claims["BotCore/priceId"];
  currentSessionInformation.userSubscriptionPlanId = claims["BotCore/planId"];
}
