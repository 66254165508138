import type { TenantAccount } from "@/types/tenant";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { QueryClient, UseQueryReturnType as _ } from "@tanstack/vue-query";

export function useTenantAccountList(enabled: boolean | globalThis.Ref<boolean>) {
  const request = useQuery({
    queryKey: ["tenant", "account-list"],
    queryFn: async () => {
      const response = await useGetRequest<{ items: TenantAccount[] }>(
        `/account/sub-accounts`
      );
      return response?.data?.items || null;
    },
    staleTime: 3 * 60 * 1000,
    enabled,
  });

  return request;
}

export function removeTenantAccountFromQueryCache(
  accountId: string,
  queryClient: QueryClient
) {
  queryClient.setQueryData(
    ["tenant", "account-list"],
    (oldData: TenantAccount[] | undefined) => {
      if (!oldData) return oldData;
      return oldData.filter(account => account.id !== accountId);
    }
  );
}
