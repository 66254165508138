<template>
  <div class="h-max">
    <button
      class="p-2 bg-slate-100 dark:bg-slate-100/10 rounded-full hover:text-primary relative"
      @click="jobsStore.openNotificationPanel()"
    >
      <BellIcon class="h-5 w-5" />

      <span
        v-if="jobsStore.unreadCount"
        class="absolute -top-2 -right-2 rounded-lg bg-red-500 text-white text-xs px-2"
      >
        {{ jobsStore.unreadCount }}
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { BellIcon } from "@heroicons/vue/24/outline";
useJobListRequest();

const jobsStore = useJobsStore();
</script>
