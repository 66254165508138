<template>
  <div class="relative flex">
    <button
      ref="trigger"
      class="inline-flex justify-center items-center group relative"
      aria-haspopup="true"
      @click.prevent="toggleDropdown()"
      :aria-expanded="dropdownOpen"
    >
      <div
        v-if="!avatarUrl"
        class="flex items-center justify-center h-8 w-8 text-white bg-slate-400 dark:bg-slate-700 rounded-full"
      >
        <UserIcon class="h-5 w-5" />
      </div>
      <img
        v-else
        class="w-7 h-7 sm:h-8 sm:w-8 bg-slate-400 dark:bg-slate-700 rounded-full"
        :src="avatarUrl"
        width="32"
        height="32"
        :alt="$t('wordsUserProfilePicture')"
      />
      <div class="sm:absolute left-8">
        <svg
          class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 duration-150"
          :class="dropdownOpen ? '-scale-y-100' : ''"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-[99] absolute top-full w-32 min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div
          class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700"
        >
          <!-- <div class="font-medium text-slate-800 dark:text-slate-100">Acme Inc.</div> -->
          <div class="text-xs text-slate-500 dark:text-slate-400 italic">
            {{ displayName || $t("wordsAccount") }}
          </div>
        </div>
        <ul
          ref="dropdown"
          @focusin="toggleDropdown(true)"
          @focusout="toggleDropdown(false)"
        >
          <li v-if="!hideSettings">
            <router-link
              class="font-medium text-sm text-primary-500 hover:text-primary-600 dark:hover:text-primary-400 flex items-center py-1 px-3"
              to="/settings"
              @click="toggleDropdown(false)"
              >{{ $t("settings.settings") }}</router-link
            >
          </li>
          <li>
            <button
              class="font-medium text-sm text-red-500 hover:text-red-600 dark:hover:text-red-400 flex items-center py-1 px-3"
              to="/signin"
              @click="signOut"
            >
              {{ $t("auth.signOut") }}
            </button>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { UserIcon } from "@heroicons/vue/24/outline";

defineProps<{
  align?: "left" | "right";
  displayName?: string;
  hideSettings?: boolean;
  avatarUrl?: string;
}>();

const emit = defineEmits(["sign-out"]);

const [dropdownOpen, toggleDropdown] = useToggle(false);
const trigger = ref<HTMLButtonElement>();
const dropdown = ref<HTMLDivElement>();

function signOut() {
  toggleDropdown(false);
  emit("sign-out");
}

const clickHandler = ({ target }: MouseEvent) => {
  if (
    !dropdownOpen.value ||
    dropdown.value?.contains(target as Node) ||
    trigger.value?.contains(target as Node)
  )
    return;
  dropdownOpen.value = false;
};

// close if the esc key is pressed
const keyHandler = ({ keyCode }: KeyboardEvent) => {
  if (!dropdownOpen.value || keyCode !== 27) return;
  dropdownOpen.value = false;
};

onMounted(() => {
  document.addEventListener("click", clickHandler);
  document.addEventListener("keydown", keyHandler);
});

onUnmounted(() => {
  document.removeEventListener("click", clickHandler);
  document.removeEventListener("keydown", keyHandler);
});
</script>
