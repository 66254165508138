import type { V2ConversationResponse } from "@/types/v2";
import type { V2ConversationMessage } from "./types";

export const MESSAGE_PREVIEW_DURATION = 10 * SECOND;

export const defaultResponseOverlayMinWidth = 200;
export const defaultResponseOverlayMaxWidth = Math.min(800, window.innerWidth * 0.5);
export const defaultResponseOverlayWidth = Math.min(360, window.innerWidth);

export const useV2ResponseStore = defineStore("v2Response", () => {
  // Show chat bubble instead of full overlay by default
  const isResponseOverlayCollapsed = ref(true);
  const messages = ref<V2ConversationMessage[]>([]);
  const selectedSuggestion = ref("");
  const unreadCount = ref(0);
  const lastMessage = ref<V2ConversationMessage | null>(null);
  const isPreviewDismissed = ref(false);
  const lastPreviewUpdate = ref(0);
  const lastConversationId = ref("");
  const responseOverlayWidth = useLocalStorage(
    "responseOverlayWidth",
    defaultResponseOverlayWidth
  );
  const responseOverlayMinWidth = ref(defaultResponseOverlayMinWidth);
  const responseOverlayMaxWidth = ref(defaultResponseOverlayMaxWidth);

  const hasResponse = computed(() => {
    return true;
  });

  const responseCount = computed(() => {
    return 3;
  });

  function hideResponseOverlay() {
    isResponseOverlayCollapsed.value = true;
  }

  function dismissPreview() {
    isPreviewDismissed.value = true;
  }

  function showResponseOverlay() {
    isResponseOverlayCollapsed.value = false;
    unreadCount.value = 0;
    dismissPreview();
  }

  function addMessage(
    _message: V2ConversationResponse["step"],
    sender: "assistant" | "user"
  ) {
    const message = { ..._message, sender };
    messages.value.push(message);
    lastMessage.value = message;
    if (sender === "assistant" && isResponseOverlayCollapsed.value) {
      unreadCount.value += 1;
      lastPreviewUpdate.value = Date.now();
      isPreviewDismissed.value = false;
    }
  }
  return {
    messages,
    unreadCount,
    hasResponse,
    responseCount,
    selectedSuggestion,
    isResponseOverlayCollapsed,
    isPreviewDismissed,
    lastConversationId,
    dismissPreview,
    responseOverlayWidth,
    responseOverlayMinWidth,
    responseOverlayMaxWidth,
    lastPreviewUpdate,
    lastMessage,
    addMessage,
    hideResponseOverlay,
    showResponseOverlay,
  };
});
