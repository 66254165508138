import type { SocialPlatform } from "@/types/account";
import type { AssistantPlatformPosts } from "@/types/assistant";

export function getAssistantsPostsQueryKey(platform: SocialPlatform, id: string) {
  return ["assistant-social-posts", platform, id];
}
export function useAssistantSocialPostRequest(id: string, platform: SocialPlatform) {
  const query = useInfiniteQuery({
    queryKey: getAssistantsPostsQueryKey(platform, id),
    queryFn: async ({ pageParam }: { pageParam?: string } = {}) => {
      const response = await useGetRequest<{
        items: AssistantPlatformPosts[typeof platform];
        next?: string;
      }>("/account/data", {
        params: {
          itemId: id,
          getSubItems: true,
          next: pageParam ? pageParam : undefined,
        },
      });
      return response.data || null;
    },
    initialPageParam: "",
    getNextPageParam: lastPage => lastPage?.next || null,
    select: data => {
      return data.pages.map(page => page.items).flat();
    },
    staleTime: 3 * MINUTE,
  });
  return query;
}

export function useAssistantSocialShortVideoRequest(
  id: string,
  platform: SocialPlatform
) {
  const query = useInfiniteQuery({
    queryKey: ["assistant-social-short-videos", platform, id],
    queryFn: async ({ pageParam }: { pageParam?: string } = {}) => {
      const response = await useGetRequest<{
        items: AssistantPlatformPosts[typeof platform];
        next?: string;
      }>("/account/data", {
        params: {
          itemId: id,
          getSubItems: true,
          next: pageParam ? pageParam : undefined,
        },
      });
      return response.data || null;
    },
    initialPageParam: "",
    getNextPageParam: lastPage => lastPage?.next || null,
    select: data => {
      return data.pages.map(page => page.items).flat();
    },
    staleTime: 3 * MINUTE,
    enabled: platform === "facebook",
  });
  return query;
}
