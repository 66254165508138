import type { ITourData } from "@/types/tour";

export const useTourStore = defineStore("tour", () => {
  const { mutateAsync: accountUpdateAsync } = useAccountUpdate();

  const tourDefaults = {
    active: false,
    dismissed: [],
    skipped: false,
    complete: false,
  };

  const welcomeTourStorage = localStorage.getItem("tour-welcome")
    ? useLocalStorage<ITourData>("tour-welcome", tourDefaults)
    : null;

  const welcomeTour = reactive<ITourData>({
    ...(welcomeTourStorage?.value || tourDefaults),
  });
  const isFeatureEnabled = hasFeatureFlag("welcomeTour");

  async function skipWelcomeTour() {
    welcomeTour.skipped = true;
    welcomeTour.active = false;
    await accountUpdateAsync({
      welcomeTour: {
        ...welcomeTour,
      },
    });
  }

  async function startWelcomeTour() {
    if (!isFeatureEnabled) return;
    welcomeTour.active = true;
    welcomeTour.dismissed = [];
    welcomeTour.complete = false;
    await accountUpdateAsync({
      welcomeTour: { ...welcomeTour },
    });
  }

  async function dismissTour(id: string) {
    welcomeTour.dismissed = [...welcomeTour.dismissed, id];
    await accountUpdateAsync({
      welcomeTour: {
        ...welcomeTour,
      },
    });
  }

  async function endWelcomeTour() {
    welcomeTour.dismissed = [];
    welcomeTour.active = false;
    welcomeTour.complete = true;
    await accountUpdateAsync({
      welcomeTour: { ...welcomeTour },
    });
  }

  async function setTourAccountData(data?: ITourData) {
    if (!data && welcomeTourStorage?.value) {
      await accountUpdateAsync({
        welcomeTour: {
          ...welcomeTourStorage.value,
        },
      });

      welcomeTourStorage.value = null;
      return;
    }

    if (welcomeTourStorage?.value) {
      welcomeTourStorage.value = null;
    }

    welcomeTour.active = data?.active ?? tourDefaults.active;
    welcomeTour.skipped = data?.skipped ?? tourDefaults.skipped;
    welcomeTour.complete = data?.complete ?? tourDefaults.complete;
    welcomeTour.dismissed = data?.dismissed || tourDefaults.dismissed;
  }

  return {
    setTourAccountData,
    welcomeTour,
    skipWelcomeTour,
    dismissTour,
    startWelcomeTour,
    endWelcomeTour,
  };
});
