import type { AssistantWebsiteResponse } from "@/types/assistant";

export function getAssistantWebsiteDataQueryKey() {
  return ["assistant-data", "website"];
}

export function useAssistantWebsiteDataRequest() {
  const query = useQuery({
    queryKey: getAssistantWebsiteDataQueryKey(),
    queryFn: async () => {
      const response = await useGetRequest<{ items: AssistantWebsiteResponse[] }>(
        "/account/data",
        {
          params: {
            keys: "website",
            getSummary: true,
          },
        }
      );
      return response?.data || null;
    },
    staleTime: 5 * MINUTE,
    select: data => {
      if (!data.items?.length) return;
      return data.items[0];
    },
  });
  return query;
}
