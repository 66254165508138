import type { IFileItem } from "@/types/formData";

export interface UploadSingleFileState {
  prepared: boolean;
  preparing: boolean;
  uploading: boolean;
  success: boolean;
  error: boolean;
  progress: number;
  data: IFileItem | null;
  message: string;
}

export function uploadSingleFile(id: string) {
  const file = ref<null | File>(null);

  const state = ref<UploadSingleFileState>({
    prepared: false,
    preparing: false,
    uploading: false,
    success: false,
    error: false,
    progress: 0,
    data: null,
    message: "",
  });

  async function upload(theFile: File | null, progressCallback?: (event: any) => any) {
    try {
      if (!theFile) {
        throw new Error("Missing File");
      }

      file.value = theFile;
      state.value.preparing = true;

      // save in database, get presigned url
      const { data } = await getPreSignedUploadUrl({
        name: file.value.name,
        contentLength: file.value.size,
        contentType: file.value.type,
      });

      const { presigned, create } = data.response;

      // state
      state.value.data = create;
      state.value.prepared = true;
      state.value.preparing = false;
      state.value.uploading = true;

      // setup form data to post to s3
      const formData = new FormData();
      // add all fields
      Object.entries(presigned.fields).forEach(([key, value]: any) => {
        formData.append(key, value);
      });
      // add file
      formData.append("file", file.value);

      // save to s3
      const response = await uploadFile(presigned.url, formData, event => {
        if (event.progress) {
          state.value.progress = event.progress;
        }
        if (typeof progressCallback === "function") {
          progressCallback(event);
        }
      });

      if (response.status !== 204) {
        throw new Error("There was a problem uploading.");
      }

      state.value.success = true;

      return create;
    } catch (e) {
      state.value.error = true;
      state.value.message = (e as Error).message;
    } finally {
      state.value.uploading = false;
      state.value.prepared = false;
      state.value.preparing = false;
    }
  }

  return {
    id,
    upload,
    state,
  };
}
