<template>
  <div
    ref="wrapper"
    class="resizable-component"
    :style="style"
    :class="!resizing ? 'duration-300' : ''"
  >
    <slot :ready="ready" :resizing="resizing" />
    <div
      v-for="el in active"
      :key="el"
      :class="'resizable-' + el"
      :id="'resizable-' + el"
      class="bg-primary-200 dark:bg-primary-800 hover:bg-primary-400 dark:hover:bg-primary-600 duration-200"
    />
  </div>
</template>

<script setup lang="ts">
const ELEMENT_MASK = {
  "resizable-r": { bit: 0b0001, cursor: "e-resize" },
  "resizable-rb": { bit: 0b0011, cursor: "se-resize" },
  "resizable-b": { bit: 0b0010, cursor: "s-resize" },
  "resizable-lb": { bit: 0b0110, cursor: "sw-resize" },
  "resizable-l": { bit: 0b0100, cursor: "w-resize" },
  "resizable-lt": { bit: 0b1100, cursor: "nw-resize" },
  "resizable-t": { bit: 0b1000, cursor: "n-resize" },
  "resizable-rt": { bit: 0b1001, cursor: "ne-resize" },
  "drag-el": { bit: 0b1111, cursor: "pointer" },
};

type ElementMaskKey = keyof typeof ELEMENT_MASK;

const CALC_MASK = {
  l: 0b0001,
  t: 0b0010,
  w: 0b0100,
  h: 0b1000,
};

const handleOptions = ["r", "rb", "b", "lb", "l", "lt", "t", "rt"] as const;

type HandleOption = (typeof handleOptions)[number];
type StrOrNum = string | number;
const props = withDefaults(
  defineProps<{
    width?: StrOrNum;
    height?: StrOrNum;
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number;
    left?: number;
    top?: number;
    active?: HandleOption[];
    fitParent?: boolean;
    dragSelector?: string;
    disableAttributes?: HandleOption[];
  }>(),
  {
    minWidth: 0,
    minHeight: 0,
    left: 0,
    top: 0,
    width: 200,
    height: 200,
    active: () => ["t", "r", "b", "l"],
  }
);

const emit = defineEmits<{
  (e: "mount", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "destroy"): void;
  (e: "resize:start", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "drag:start", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "resize:end", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "drag:end", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "resize:move", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "drag:move", data: { width: StrOrNum; height: StrOrNum }): void;
  (e: "maximize", payload: { state: boolean }): void;
}>();

const { maxWidth, maxHeight, minWidth, minHeight, top, left, width, height } =
  toRefs(props);
const wrapper = ref<HTMLDivElement>();
const data = reactive({
  w: props.width,
  h: props.height,
  minW: props.minWidth,
  minH: props.minHeight,
  maxW: props.maxWidth,
  maxH: props.maxHeight,
  l: props.left,
  t: props.top,
  mouseX: 0,
  mouseY: 0,
  offsetX: 0,
  offsetY: 0,
  parent: { width: 0, height: 0 },
  resizeState: 0,
  dragState: false,
  calcMap: 0b1111,
});
const ready = ref(false);
const resizing = ref(false);

const dragElements = shallowRef<NodeListOf<Element>[]>([]);

const style = computed(() => {
  return {
    ...(data.calcMap & CALC_MASK.w && {
      width: typeof data.w === "number" ? data.w + "px" : data.w,
    }),
    ...(data.calcMap & CALC_MASK.h && {
      height: typeof data.h === "number" ? data.h + "px" : data.h,
    }),
    ...(data.calcMap & CALC_MASK.l && {
      left: typeof data.l === "number" ? data.l + "px" : data.l,
    }),
    ...(data.calcMap & CALC_MASK.t && {
      top: typeof data.t === "number" ? data.t + "px" : data.t,
    }),
  };
});

function setupDragElements(selector: string) {
  if (!wrapper.value) return;
  const oldList = wrapper.value.querySelectorAll(".drag-el");
  oldList.forEach(el => {
    el.classList.remove("drag-el");
  });

  const nodeList = wrapper.value.querySelectorAll(selector);
  nodeList.forEach(el => {
    el.classList.add("drag-el");
  });
  dragElements.value = Array.prototype.slice.call(nodeList);
}

function handleUp() {
  if (data.resizeState !== 0) {
    document.body.style.cursor = "";
    const payload = { width: data.w, height: data.h };
    const eventName = !data.dragState ? "resize:end" : "drag:end";
    emit(eventName as any, payload);
    data.resizeState = 0;
    setTimeout(() => {
      resizing.value = false;
    }, 30);
    data.dragState = false;
  }
}

function handleDown(event: MouseEvent | TouchEvent) {
  if (!wrapper.value) return;
  const parentElement = wrapper.value.parentElement;
  const target = event.target as HTMLElement;
  if (target && target.closest(".resizable-component") !== wrapper.value) return;

  const touchEvent = event as TouchEvent;
  const mouseEvent = event as MouseEvent;
  for (let elClass in ELEMENT_MASK) {
    if (
      wrapper.value.contains(target) &&
      ((target.closest && target.closest(`.${elClass}`)) ||
        target.classList.contains(elClass))
    ) {
      elClass === "drag-el" && (data.dragState = true);
      document.body.style.cursor = ELEMENT_MASK[elClass as ElementMaskKey]?.cursor;
      if (touchEvent.touches && touchEvent.touches.length >= 1) {
        data.mouseX = touchEvent.touches[0].clientX;
        data.mouseY = touchEvent.touches[0].clientY;
      } else {
        event.preventDefault && event.preventDefault();
        data.mouseX = mouseEvent.clientX;
        data.mouseY = mouseEvent.clientY;
      }
      data.offsetX = data.offsetY = 0;
      data.resizeState = ELEMENT_MASK[elClass as ElementMaskKey].bit;
      if (!parentElement) return;
      data.parent.height = parentElement.clientHeight;
      data.parent.width = parentElement.clientWidth;
      const payload = { width: data.w, height: data.h };
      !data.dragState ? emit("resize:start", payload) : emit("drag:start", payload);
      break;
    }
  }
}

const handleMove = useThrottleFn((event: MouseEvent | TouchEvent) => {
  if (!wrapper.value) return;
  if (data.resizeState === 0) return;
  resizing.value = true;
  const mouseEvent = event as MouseEvent;
  const touchEvent = event as TouchEvent;
  // const h = data.h && typeof data.h === "number" ? data.h : 0;
  // const w = data.w && typeof data.w === "number" ? data.w : 0;
  if (!data.dragState) {
    /**
     * TODO: Update to consider the type of resize
     */
    if (typeof data.w === "number" && !isNaN(+data.w)) {
      data.w = wrapper.value.clientWidth;
    }
    if (typeof data.h === "number" && !isNaN(+data.h)) {
      data.h = wrapper.value.clientHeight;
    }
  }
  let eventY, eventX;
  if (touchEvent.touches && touchEvent.touches.length >= 0) {
    eventY = touchEvent.touches[0].clientY;
    eventX = touchEvent.touches[0].clientX;
  } else {
    eventY = mouseEvent.clientY;
    eventX = mouseEvent.clientX;
  }
  let diffX = eventX - data.mouseX + data.offsetX,
    diffY = eventY - data.mouseY + data.offsetY;
  // @ts-ignore
  if (wrapper.value.getBoundingClientRect) {
    const rect = wrapper.value.getBoundingClientRect();
    const scaleX = rect.width / wrapper.value.offsetWidth || 1;
    const scaleY = rect.height / wrapper.value.offsetHeight || 1;
    diffX /= scaleX;
    diffY /= scaleY;
  }
  data.offsetX = data.offsetY = 0;
  if (data.resizeState & ELEMENT_MASK["resizable-r"].bit) {
    const w =
      typeof data.w === "number"
        ? data.w
        : data.minW || wrapper.value.getBoundingClientRect().width;
    if (!data.dragState && w + diffX < data.minW)
      data.offsetX = diffX - (diffX = data.minW - w);
    else if (
      !data.dragState &&
      data.maxW &&
      w + diffX > data.maxW &&
      (!props.fitParent || w + data.l < data.parent.width)
    )
      data.offsetX = diffX - (diffX = data.maxW - w);
    else if (props.fitParent && data.l + w + diffX > data.parent.width)
      data.offsetX = diffX - (diffX = data.parent.width - data.l - w);
    if (data.calcMap & CALC_MASK.w) {
      const val = data.dragState ? 0 : diffX;
      data.w = typeof data.w === "number" ? data.w + val : data.w;
    }
  }
  if (data.resizeState & ELEMENT_MASK["resizable-b"].bit) {
    const h =
      typeof data.h === "number"
        ? data.h
        : data.minH || wrapper.value.getBoundingClientRect().height;
    if (!data.dragState && h + diffY < data.minH)
      data.offsetY = diffY - (diffY = data.minH - h);
    else if (
      !data.dragState &&
      data.maxH &&
      h + diffY > data.maxH &&
      (!props.fitParent || h + data.t < data.parent.height)
    )
      data.offsetY = diffY - (diffY = data.maxH - h);
    else if (props.fitParent && data.t + h + diffY > data.parent.height)
      data.offsetY = diffY - (diffY = data.parent.height - data.t - h);

    if (data.calcMap & CALC_MASK.h) {
      const val = data.dragState ? 0 : diffY;
      data.h = typeof data.h === "number" ? data.h + val : data.h + 0;
    }
  }
  if (data.resizeState & ELEMENT_MASK["resizable-l"].bit) {
    const w =
      typeof data.w === "number" ? data.w : data.minW || wrapper.value.clientWidth;
    if (!data.dragState && w - diffX < data.minW)
      data.offsetX = diffX - (diffX = w - data.minW);
    else if (!data.dragState && data.maxW && w - diffX > data.maxW && data.l >= 0)
      data.offsetX = diffX - (diffX = w - data.maxW);
    else if (props.fitParent && data.l + diffX < 0)
      data.offsetX = diffX - (diffX = -data.l);

    // data.calcMap & CALC_MASK.l && (data.l += diffX);
    // data.calcMap & CALC_MASK.w && (data.w -= data.dragState ? 0 : diffX);
    if (data.calcMap & CALC_MASK.w) {
      const val = data.dragState ? 0 : diffX;
      data.w = typeof data.w === "number" ? data.w - val : data.w;
    }
  }
  if (data.resizeState & ELEMENT_MASK["resizable-t"].bit) {
    const h =
      typeof data.h === "number"
        ? data.h
        : data.minH || wrapper.value.getBoundingClientRect().height;
    if (!data.dragState && h - diffY < data.minH)
      data.offsetY = diffY - (diffY = h - data.minH);
    else if (!data.dragState && data.maxH && h - diffY > data.maxH && data.t >= 0)
      data.offsetY = diffY - (diffY = h - data.maxH);
    else if (props.fitParent && data.t + diffY < 0)
      data.offsetY = diffY - (diffY = -data.t);

    // data.calcMap & CALC_MASK.t && (data.t += diffY);
    // data.calcMap & CALC_MASK.h && (data.h -= data.dragState ? 0 : diffY);
    if (data.calcMap & CALC_MASK.h) {
      const val = data.dragState ? 0 : diffY;
      data.h = typeof data.h === "number" ? data.h - val : h;
    }
  }
  data.mouseX = eventX;
  data.mouseY = eventY;
  const payload = { width: data.w, height: data.h };
  !data.dragState ? emit("resize:move", payload) : emit("drag:move", payload);
}, 20);

onMounted(() => {
  if (!wrapper.value || !wrapper.value.parentElement) return;
  const parentElement = wrapper.value.parentElement;
  if (!props.width && wrapper.value?.parentElement) {
    data.w = wrapper.value.parentElement.clientWidth;
  } else if (typeof props.width !== "string") {
    typeof props.width !== "number" && (data.w = wrapper.value.clientWidth);
  }
  if (!props.height) {
    data.h = wrapper.value.parentElement.clientHeight;
    // @ts-ignore
  } else if (props.height !== "auto" && props.height !== "100%") {
    typeof props.height !== "number" && (data.h = wrapper.value.clientHeight);
  }
  typeof props.left !== "number" &&
    (data.l = wrapper.value.offsetLeft - parentElement.offsetLeft);
  typeof props.top !== "number" &&
    (data.t = wrapper.value.offsetTop - parentElement.offsetTop);
  props.minWidth &&
    typeof data.w === "number" &&
    data.w < props.minWidth &&
    (data.w = props.minWidth);
  props.minHeight &&
    typeof data.h === "number" &&
    data.h < props.minHeight &&
    (data.h = props.minHeight);
  props.maxWidth &&
    typeof data.w === "number" &&
    data.w > props.maxWidth &&
    (data.w = props.maxWidth);
  props.maxHeight &&
    typeof data.h === "number" &&
    data.h > props.maxHeight &&
    (data.h = props.maxHeight);

  props.dragSelector && setupDragElements(props.dragSelector);

  props.disableAttributes &&
    props.disableAttributes.forEach(attr => {
      switch (attr) {
        case "l":
          data.calcMap &= ~CALC_MASK.l;
          break;
        case "t":
          data.calcMap &= ~CALC_MASK.t;
          break;
        // @ts-ignore
        case "w":
          data.calcMap &= ~CALC_MASK.w;
          break;
        // @ts-ignore
        case "h":
          data.calcMap &= ~CALC_MASK.h;
      }
    });

  document.documentElement.addEventListener("mousemove", handleMove, true);
  document.documentElement.addEventListener("mousedown", handleDown, true);
  document.documentElement.addEventListener("mouseup", handleUp, true);

  document.documentElement.addEventListener("touchmove", handleMove, true);
  document.documentElement.addEventListener("touchstart", handleDown, true);
  document.documentElement.addEventListener("touchend", handleUp, true);
  const payload = { width: data.w, height: data.h };
  emit("mount", payload);
  ready.value = true;
});

onBeforeUnmount(() => {
  document.documentElement.removeEventListener("mousemove", handleMove, true);
  document.documentElement.removeEventListener("mousedown", handleDown, true);
  document.documentElement.removeEventListener("mouseup", handleUp, true);

  document.documentElement.removeEventListener("touchmove", handleMove, true);
  document.documentElement.removeEventListener("touchstart", handleDown, true);
  document.documentElement.removeEventListener("touchend", handleUp, true);
  emit("destroy");
});

watchEffect(() => {
  if (maxWidth?.value) data.maxW = maxWidth.value;
  if (maxHeight?.value) data.maxH = maxHeight.value;
  data.minW = minWidth.value ?? data.minW;
  data.minH = minHeight.value ?? data.minH;
  data.w = width.value ?? data.w;
  data.h = height.value ?? data.h;
  data.l = left.value ?? data.l;
  data.t = top.value ?? data.t;
  if (props.dragSelector) setupDragElements(props.dragSelector);
});
</script>

<style scoped>
.resizable-component {
  position: relative;
}

.resizable-component > .resizable-r {
  display: block;
  position: absolute;
  z-index: 40;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: e-resize;
  width: 12px;
  right: -6px;
  top: 0;
  height: 100%;
}

.resizable-component > .resizable-rb {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: -6px;
  bottom: -6px;
  z-index: 40;
}

.resizable-component > .resizable-b {
  display: block;
  position: absolute;
  z-index: 40;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: s-resize;
  height: 12px;
  bottom: -6px;
  width: 100%;
  left: 0;
}

.resizable-component > .resizable-lb {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: sw-resize;
  width: 12px;
  height: 12px;
  left: -6px;
  bottom: -6px;
  z-index: 91;
}

.resizable-component > .resizable-l {
  display: block;
  position: absolute;
  z-index: 40;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: w-resize;
  width: 4px;
  left: -1px;
  height: 100%;
  top: 0;
}

.resizable-component > .resizable-lt {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: nw-resize;
  width: 12px;
  height: 12px;
  left: -6px;
  top: -6px;
  z-index: 91;
}

.resizable-component > .resizable-t {
  display: block;
  position: absolute;
  z-index: 40;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: n-resize;
  height: 6px;
  top: -6px;
  width: 100%;
  left: 0;
}

.resizable-component > .resizable-rt {
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: ne-resize;
  width: 12px;
  height: 12px;
  right: -6px;
  top: -6px;
  z-index: 40;
}
</style>
