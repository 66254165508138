<template>
  <Button
    :iconPos="iconPos"
    :severity="variant"
    :text="text"
    :outlined="outlined"
    :disabled="disabled"
    :loading="loading"
    :icon="icon"
    :type="type"
    :label="label"
    :class="{ 'w-full': fullWidth }"
    @click="onButtonClick"
  >
    <slot></slot>
  </Button>
</template>
<script lang="ts" setup>
import Button from "primevue/button";
import type { ButtonHTMLAttributes } from "vue";
const router = useRouter();

const props = withDefaults(
  defineProps<{
    loading?: boolean;
    icon?: string;
    label?: string;
    variant?: "secondary" | "danger";
    disabled?: boolean;
    text?: boolean;
    outlined?: boolean;
    fullWidth?: boolean;
    type?: ButtonHTMLAttributes["type"];
    iconPos?: "right" | "left";
    to?: string | { name: string; params?: Record<string, any> };
  }>(),
  {
    variant: undefined,
    iconPos: "right",
  }
);

function onButtonClick() {
  if (props.to) {
    router.push(props.to);
  }
}
</script>
