import type { SocialPlatform } from "@/types/account";
import type { AssistantProcessingResponse } from "@/types/assistant";

export function getAssistantResourceStateKey(
  platform:
    | SocialPlatform
    | "website"
    | "competitorWebsite"
    | `competitorWebsite#${string}`
) {
  return ["assistant-data", "state", platform];
}

export function useAssistantResourceStateRequest(
  platform: SocialPlatform | "website" | "competitorWebsite",
  enabled: globalThis.Ref
) {
  const query = useQuery({
    queryKey: getAssistantResourceStateKey(platform),
    queryFn: async () => {
      const response = await useGetRequest<AssistantProcessingResponse>("/account/data", {
        params: {
          itemId: platform,
          getSummary: true,
        },
      });
      return response?.data || null;
    },
    staleTime: 5 * MINUTE,
    enabled,
  });
  return query;
}
