import type { V2ChatPayload, V2ConversationResponse } from "@/types/v2";

export function useV2ConversationRequest() {
  const v2ResponseStore = useV2ResponseStore();
  const queryClient = useQueryClient();
  const router = useRouter();

  const request = useMutation({
    mutationKey: ["v2", "conversation"],
    mutationFn: async (data: V2ChatPayload) => {
      "/v2/chat";
      const response = await usePostRequest<V2ConversationResponse>("/hey", data);
      return response.data || null;
    },

    onMutate(payload) {
      // Optimistic updates
      const conversationId = payload.dialogueId;
      const text = formatInput(payload.inputContent || []);
      console.log({ text, inputContent: payload.inputContent });
      v2ResponseStore.addMessage(
        {
          errors: [],
          fields: [],
          hints: [],
          statement: text,
          step: "input",
        },
        "user"
      );

      if (conversationId) {
        addMessageToConversationDetails(
          conversationId,
          {
            content: text,
            role: "user",
          },
          queryClient
        );
      }

      return {
        oldConversationId: conversationId,
        plainTextInput: text,
      };
    },

    onSuccess(data, payload, context) {
      // Will probably have to check if the response overlay is open in some cases? needs some testing
      if (!data?.step) return;
      const newConversationId = data.state?.dialogueId;
      const oldConversationId = context?.oldConversationId;

      // Active, open conversation
      if (
        newConversationId &&
        oldConversationId &&
        oldConversationId === newConversationId
      ) {
        addMessageToConversationDetails(
          newConversationId,
          {
            content: data.step.statement,
            role: "assistant",
          },
          queryClient
        );
      }
      // New conversation
      else if (newConversationId && !oldConversationId) {
        // backend auto-assigned conversation and we have it cached?
        const existing = getCachedConversationDetailsById(newConversationId, queryClient);
        if (existing) {
          addMessageToConversationDetails(
            newConversationId,
            {
              content: context?.plainTextInput || "",
              role: "user",
            },
            queryClient
          );
          addMessageToConversationDetails(
            newConversationId,
            {
              content: data.step.statement,
              role: "assistant",
            },
            queryClient
          );
        } else {
          v2ResponseStore.addMessage(data.step, "assistant");
          // fetch it in BG && navigate
          prefetchSingleMessageDetails(newConversationId, queryClient).then(() => {
            // Maybe not? will have to test
            if (v2ResponseStore.isResponseOverlayCollapsed) return;
            router.replace({
              query: {
                ...router.currentRoute.value.query,
                conversationId: newConversationId,
              },
            });
          });
        }
      }
      // in a conversation, but message was assigned to a different conversation, or new conversation that returned no valid response (no id)
      else {
        console.log("other conversation", newConversationId, oldConversationId);
      }
    },
  });

  return request;
}

//
// UTILS
//
function formatInput(blocks: V2ChatPayload["inputContent"]) {
  if (!blocks) return "";
  return blocks
    .map((b: any) => b.data?.text)
    .filter(t => !!t)
    .join("");
}

export function handleV2ConversationMessageEvent(data: { dialogueId: string }) {
  const v2ResponseStore = useV2ResponseStore();
  const { dialogueId = "" } = data;
  if (!dialogueId) return;
  v2ResponseStore.lastConversationId = dialogueId;
  console.log(data);
}
