<template>
  <div class="duration-300 backdrop-blur bg-slate-300/80 dark:bg-slate-800/90 h-full">
    <div
      class="p-4 z-20 flex justify-between absolute top-0 left-0 w-full bg-white/60 dark:bg-slate-800/60 backdrop-blur border-b dark:border-slate-700/50"
    >
      <!-- Recent Conversations -->
      <button
        class="hover:text-primary duration-200 rounded-full"
        id="recent-conversations-toggle"
        @click="toggleRecentConversations()"
      >
        <Bars3BottomLeftIcon class="h-8 w-8" />
      </button>

      <!-- Close -->
      <button
        class="rounded-full flex items-center justify-center h-10 w-10 bg-white dark:bg-slate-900 hover:text-white hover:bg-red-600 dark:hover:bg-red-500 duration-200"
        @click="v2ResponseStore.hideResponseOverlay"
      >
        <XMarkIcon class="h-6 w-6" />
      </button>
    </div>

    <!-- Content -->
    <div class="content h-full overflow-hidden w-full">
      <transition
        enter-from-class="-left-7 opacity-0"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0 -left-7"
      >
        <RecentConversationList
          v-if="recentConversationsOpen"
          @close="toggleRecentConversations(false)"
        />
      </transition>
      <div class="h-full">
        <V2ConversationWrapper
          v-if="v2ResponseStore.messages?.length || activeConversationId"
          :messages="messages?.messages || []"
          :is-recent="!!activeConversationId"
          :loading="isLoading"
          :error="isError"
          @retry="refetch"
        />
        <DefaultConversationView v-else />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import DefaultConversationView from "./conversation/DefaultConversationView.vue";
import V2ConversationWrapper from "./conversation/V2ConversationWrapper.vue";
import RecentConversationList from "./conversation/RecentConversationList.vue";

const v2ResponseStore = useV2ResponseStore();
const [recentConversationsOpen, toggleRecentConversations] = useToggle();

const activeConversationId = computed(() => v2ResponseStore.lastConversationId);

const {
  data: messages,
  isLoading,
  isError,
  refetch,
} = useV2SingleConversationDetailsRequest(activeConversationId);

onBeforeMount(() => {
  if (!activeConversationId.value) toggleRecentConversations(true);
});
</script>
