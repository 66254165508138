import type { JobNotificationData } from "@/types/jobs";

export function useJobListRequest() {
  const enabled = computed(
    () =>
      !!currentSessionInformation.appBackendApiUrl &&
      !!currentSessionInformation.appAuthToken
  );
  const query = useInfiniteQuery({
    queryKey: ["job-notifications"],
    queryFn: async ({ pageParam }: { pageParam?: string | null } = {}) => {
      const res = await useGetRequest<{ items: JobNotificationData[]; next?: string }>(
        "/jobs?type=crawl_website&type=ProcessSocialMedia&status=complete",
        {
          params: {
            nextQuery: pageParam,
            // type: "process_website",
            // status: "complete",
            // __limit: 30,
          },
        }
      );
      return res.data || null;
    },
    initialPageParam: null,
    placeholderData: { pages: [{ items: [], next: "" }], pageParams: [] },
    getNextPageParam: res => {
      if (!res?.next) return null;
      const query = res.next?.split("?") || [];

      const searchParams = new URLSearchParams(query.slice(1).join("?"));
      const nextQuery = searchParams.get("nextQuery");

      if (!nextQuery) {
        return null;
      }
      return nextQuery;
    },
    // TODO: add select statement
    select: data =>
      (data.pages.map(p => p.items).flat() || []).sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      ),
    staleTime: 3 * MINUTE,
    enabled,
  });

  return query;
}
