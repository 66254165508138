<template>
  <div class="flex flex-col justify-center">
    <component
      :is="as || RouterLink"
      class="h-11 w-11 sm:h-9 sm:w-9 grid text-primary-500 rounded-full place-content-center border border-slate-200 dark:border-slate-700/40 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 hover:bg-primary-50 dark:hover:bg-primary-300/10 relative"
      :to="to"
    >
      <span class="sr-only">{{ $t("buttons.back") }}</span>

      <svg
        class="relative h-8 w-8 sm:h-6 sm:w-6"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          d="M13.939 4.939L6.879 12l7.06 7.061l2.122-2.122L11.121 12l4.94-4.939z"
          fill="currentColor"
        />
      </svg>
    </component>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{ to?: any; as?: string }>(), {
  to: "/",
});

const RouterLink = defineAsyncComponent(() =>
  import("vue-router").then(m => m.RouterLink)
);
</script>

<style scoped></style>
