<template>
  <router-link :to="to" custom v-slot="{ href, navigate, isActive: isRouteActive }">
    <a
      class="w-full h-6 flex items-center justify-center relative after:absolute after:w-0.5 after:right-0 after:top-0 after:bottom-0 after:hidden sm:after:block"
      :class="{
        'text-sky-500 after:bg-sky-500': isRouteActive || isActive,
        'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400':
          !isRouteActive && !isActive,
        'flex-col-reverse sm:mb-4': v2LayoutEnabled,
        'flex-col-reverse sm:mb-4 xl:mb-0 [@media(min-width:1350px)]:flex-row sm:justify-end [@media(min-width:1350px)]:pr-1.5 [@media(min-width:1350px)]:gap-2':
          !v2LayoutEnabled,
      }"
      :href="href"
      @click="navigate"
    >
      <span class="text-xs hidden sm:block">{{ label }}</span>
      <slot name="icon"></slot>
    </a>
  </router-link>
</template>

<script setup lang="ts">
defineProps<{
  isActive?: boolean;
  label: string;
  to: { name: string; params?: Record<string, any> };
}>();

const { v2LayoutEnabled } = useV2Layout();
</script>
