<template>
  <div class="flex px-2 py-1 items-start rounded-md relative w-48">
    <slot>
      <p class="text-sm italic opacity-0 animate-[fade-in_0.5s_0.5s_ease-in_forwards]">
        {{ messages }}
      </p>
    </slot>

    <button
      class="opacity-70 hover:opacity-100 duration-200 focus:outline-none focus:ring-transparent absolute -top-2.5 -right-1.5 bg-surface-200 dark:bg-surface-700 rounded-full w-7 h-7 flex justify-center items-center"
      @click="emit('close')"
      title="Hide"
    >
      <i class="pi pi-times" />
    </button>
  </div>
</template>

<script setup lang="ts">
import type { HelpDataBlock } from "@/stores/help/data";

type HelpTextBlock = {
  element: "text" | "heading";
  innerText: string;
};

const props = defineProps<{
  data: HelpDataBlock[];
}>();

const emit = defineEmits(["close"]);

const messages = computed(() => {
  // @ts-ignore -- ts doesn't evaluate conditions in Array.prototype.filter
  const textFields: HelpTextBlock[] = props.data?.filter(v => v.element === "text") || [];
  return textFields.map(v => v.innerText).join(". ");
});
</script>
