import { defaultInputStyles } from "../_utils";

export default {
  root: ({ context, props }) => ({
    class: [
      "ma-input",

      ...defaultInputStyles({
        invalid: props.invalid,
        disabled: context.disabled || props.disabled,
        size: props.size,
      }),
    ],
  }),
};
