import type { OngoingJob } from "@/types/jobs";

export const useJobsStore = defineStore("notification", () => {
  // hashmap for quick insert/delete/lookup when there is a burst ~ 20/sec
  const ongoingJobs = ref<{ [jobId: string]: OngoingJob }>({});
  const [isNotificationPanelOpen, toggleNotifications] = useToggle();
  const unreadCount = ref(0);

  const ongoingJobList = computed(() =>
    Object.values(ongoingJobs.value).sort((a, b) => {
      const values = {
        complete: 1,
        failed: 0,
        running: -1,
      };
      return values[a.status] - values[b.status];
    })
  );
  const activeJobCount = computed(() => ongoingJobList.value?.length ?? 0);

  function openNotificationPanel() {
    unreadCount.value = 0;
    toggleNotifications(true);
  }

  function closeNotificationPanel() {
    toggleNotifications(false);
  }

  function addOrUpdateOngoingJob(job: OngoingJob) {
    if (!job.data.created) job.data.created = new Date().toISOString();
    const existing = ongoingJobs.value[job.jobId];
    const isExistingFailed = existing?.status === "failed";
    // 3/10 coming after 5/10
    const isInSeriesAndIsPrevious =
      existing &&
      new Date(existing.data.created).getTime() > new Date(job.data.created).getTime();

    if (isExistingFailed || isInSeriesAndIsPrevious) return;
    ongoingJobs.value[job.jobId] = job;
    if (!isNotificationPanelOpen.value && !existing) unreadCount.value += 1;
  }
  // async method to get jobs - usually root obj

  function removeOngoingJob(jobId: string) {
    delete ongoingJobs.value[jobId];
  }

  function removeJobAfterTimeout(jobId: string, numOfSeconds = 10) {
    setTimeout(() => {
      removeOngoingJob(jobId);
    }, numOfSeconds * SECOND);
  }

  function clearAllOngoingJobs() {
    for (const jobId in ongoingJobs.value) {
      removeOngoingJob(jobId);
    }
    unreadCount.value = 0;
  }

  function handleJobEvent(data: OngoingJob) {
    if ((data.status === "complete" && !data.data) || data.status === "failed") {
      removeJobAfterTimeout(data.jobId);
    }
    addOrUpdateOngoingJob(data);
  }

  return {
    ongoingJobList,
    activeJobCount,
    ongoingJobs,
    openNotificationPanel,
    closeNotificationPanel,
    isNotificationPanelOpen,
    unreadCount,
    handleJobEvent,
    removeOngoingJob,
    clearAllOngoingJobs,
    removeJobAfterTimeout,
    addOrUpdateOngoingJob,
  };
});
