import { ulid } from "ulid";

export function useS3Uploader() {
  const queryClient = useQueryClient();

  const uploadState = ref<any[]>([]);
  const uploadRecently = ref<any[]>([]);

  function onFileInputChange(e: Event) {
    const fileList = (e.target as HTMLInputElement).files;
    handleFileInput(fileList);
  }

  function handleFileInput(fileList: FileList | File[] | null) {
    if (fileList) {
      if (fileList instanceof FileList) {
        for (let index = 0; index < fileList.length; index++) {
          const file = fileList[index];
          const id = ulid();
          const uploader = uploadSingleFile(id);
          uploadState.value.push(uploader);
          uploader.upload(file);
        }
      }
      const files = Array.isArray(fileList) ? fileList : [fileList];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file instanceof File) {
          const id = ulid();
          const uploader = uploadSingleFile(id);
          uploadState.value.push(uploader);
          uploader.upload(file);
        }
      }
    }
  }

  const isUploading = computed(() => {
    const uploading = uploadState.value.find(a => a.state.uploading);
    const preparing = uploadState.value.find(a => a.state.preparing);
    return !!uploading || !!preparing;
  });

  const uploadProgress = computed(() => {
    const uploading = uploadState.value.map(a => a.state.progress);
    return uploading.reduce((acc, current) => acc + current, 0) / uploading.length;
  });

  watch(uploadState.value, r => {
    const completed = r.filter(e => e.state.success).map(a => a.id);

    if (completed.length) {
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
      queryClient.invalidateQueries({
        queryKey: ["files", "list"],
      });

      uploadRecently.value = [...uploadState.value.filter(a => completed.includes(a.id))];
      uploadState.value = uploadState.value.filter(a => !completed.includes(a.id));
    }
  });

  function clearState() {
    if (isUploading.value) return;
    uploadState.value = [];
    uploadRecently.value = [];
  }

  return {
    uploadState,
    clearState,
    uploadRecently,
    isUploading,
    uploadProgress,
    onFileInputChange,
    handleFileInput,
  };
}
