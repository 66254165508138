<template>
  <div
    class="h-full overflow-y-auto p-4 pt-0 bg-white dark:bg-slate-800 w-full absolute left-0 top-0 duration-500 z-10 border-r dark:border-slate-700"
  >
    <div class="h-16 w-full top-0 bg-inherit z-10"></div>
    <p class="opacity-70 text-sm relative mb-3 pt-2">Recent</p>
    <template v-if="isLoading">
      <RecentConversationListItemLoading v-for="i in 5" :key="i" />
    </template>
    <ConversationListEmpty
      v-else-if="!data?.length"
      class="h-[calc(100%-6rem)]"
      @create="emit('create')"
    />
    <AppError v-else-if="isError" class="scale-75 mt-8" />
    <template v-else>
      <RecentConversationListItem
        v-for="item in data"
        :key="item.id"
        :item="item"
        :active="activeId === item.id"
        @set-active-id="setActiveId"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import AppError from "@/components/shared/AppError.vue";
import RecentConversationListItem from "./RecentConversationListItem.vue";
import RecentConversationListItemLoading from "./meta/RecentConversationListItemLoading.vue";
import ConversationListEmpty from "./meta/ConversationListEmpty.vue";

const emit = defineEmits(["close", "create"]);
const { data, isLoading, isError } = useRecentV2ConversationList();
const router = useRouter();
const v2ResponseStore = useV2ResponseStore();

const activeId = computed(
  () => router.currentRoute.value.query.activeConversationId as string
);

function setActiveId(id: string) {
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      activeConversationId: id,
    },
  });
  v2ResponseStore.lastConversationId = id;
  nextTick(() => {
    v2ResponseStore.messages = [];
    emit("close");
  });
}
</script>
