<template>
  <div>
    <template v-if="text && typeof text === 'string'">
      <VueMarkdown
        v-for="(lineBreak, line) in lines"
        :key="line"
        class="markdown-html"
        :class="className"
        :source="lineBreak"
      />
    </template>
    <template v-else-if="running"> <ArticleLoading v-for="i in 3" :key="i" /></template>
  </div>
</template>
<script lang="ts" setup>
import VueMarkdown from "vue-markdown-render";
import ArticleLoading from "@/components/loading/ArticleLoading.vue";

const props = withDefaults(
  defineProps<{
    running?: boolean;
    text: string | Record<string, any>;
    className?: string;
  }>(),
  {
    className: "markdown-default",
  }
);

const lines = computed(() => {
  const { text } = props;
  if (typeof text === "object") return {};
  return props.text.split("\\n");
});
</script>

<style>
.markdown-default h1,
.markdown-default h2 {
  @apply text-lg font-bold;
}

.markdown-default p {
  @apply mb-3;
}

.markdown-default li {
  @apply mb-1 ml-4 list-disc;
}
</style>
