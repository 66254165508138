import type { GetPresignedURLResponse } from "@/types/formData";
import type { AxiosProgressEvent } from "axios";

export function getPreSignedUploadUrl(file: Partial<any> & { contentLength: number }) {
  return usePutRequest<{ response: GetPresignedURLResponse }>("/account/files", file);
}

export async function getPreSignedDownloadUrl(
  id: string,
  type: "account" | "temporary" = "temporary"
) {
  const res = await useGetRequest<{ path: string }>("/account/files", {
    params: { id, type },
  });

  return res.data;
}

export function uploadFile(
  url: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
  return baseAxiosInstance.post(url, formData, { onUploadProgress });
}
