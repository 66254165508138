<template>
  <slot></slot>
  <div
    ref="elementWrapper"
    v-if="show"
    class="fixed w-full bottom-0 left-0"
    :style="`max-height:${containerHeight}px`"
  >
    <div class="max-w-7xl mx-auto">
      <div class="flex">
        <div
          class="fixed sm:sticky bottom-0 sm:bottom-[unset] sm:top-0 w-full sm:w-16 md:w-24 shrink-0 grow-0 z-10 no-scrollbar sm:border-r border-slate-200 dark:border-slate-700 dark:sm:border-slate-800 bg-slate-100 dark:bg-slate-800 sm:bg-transparent dark:sm:bg-transparent"
        >
          <div
            class="flex gap-4 sm:gap-0 flex-row-reverse sm:flex-col justify-between after:flex-1 after:mt-auto w-full"
          >
            <div class="flex-1">
              <div class="flex justify-end sm:justify-center mr-6 sm:mr-0 items-center">
                side
              </div>
            </div>
          </div>
        </div>
        <!-- Main content -->
        <main
          class="grow overflow-hidden pb-6 sm:pb-0 px-2 sm:px-4 md:px-6 bg-slate-100 relative"
        >
          <div class="w-full max-w-[1072px] mx-auto flex flex-col">
            <div class="grow py-4 md:py-3">
              <ChatWindow ref="elementChatWindow" />
              <TextInput ref="elementTextInput" class="w-full text-xl" />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChatWindow from "./TestInteraction/ChatWindow.vue";
import TextInput from "./TestInteraction/TextInput.vue";

const show = getEnv("VITE_SHOW_INTERACTION_LAYER") === "1";

const elementWrapper = ref(null);
const elementTextInput = ref(null);
const elementChatWindow = ref(null);


// const { height: windowHeight } = useWindowSize();
const { height: textInputHeight } = useElementSize(elementTextInput);
// const { height: wrapperHeight } = useElementSize(elementWrapper);

const containerHeight = computed(() => {
  return (textInputHeight?.value || 0) + 100;
});
</script>
