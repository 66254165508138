import type { RouteRecordRaw } from "vue-router";

export const tenantRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "tenant-home",
    redirect: () => ({ name: "tenant-accounts" }),
  },
  {
    name: "tenant-accounts",
    path: "accounts",
    component: () => import("@/views/tenant/AccountsView.vue"),
    meta: {
      titleTag: "Accounts | Marketing Assistant",
    },
  },
  {
    name: "tenant-welcome",
    path: "welcome",
    component: () => import("@/views/tenant/TenantWelcome.vue"),
    meta: {
      titleTag: "Welcome | Marketing Assistant",
    },
  },
  {
    name: "tenant-settings",
    path: "settings",
    component: () => import("@/views/tenant/TenantSettings.vue"),
    children: [
      {
        name: "tenant-account-settings",
        path: "account",
        component: () => import("@/views/tenant/settings/AccountPanel.vue"),
        meta: {
          titleTag: "Account Settings | Marketing Assistant",
        },
      },
      {
        name: "tenant-account-users",
        path: "users",
        component: () => import("@/views/tenant/settings/AccountUsers.vue"),
        meta: {
          titleTag: "Users | Marketing Assistant",
        },
      },
      {
        path: "",
        name: "tenant-settings-redirect",
        redirect: () => ({ name: "tenant-account-settings" }),
      },
      {
        path: "/",
        name: "tenant-settings-redirect2",
        redirect: () => ({ name: "tenant-account-settings" }),
      },
    ],
  },
];
