import { isEqual, isObject, transform } from "lodash";

export function maybeParseJson(input: string | Record<any, any>) {
  try {
    if (typeof input !== "string") return input;
    const data = JSON.parse(input);
    return data;
  } catch {
    return {};
  }
}

export function formatCurrency(amountInCents: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  return formatter.format(amountInCents / 100);
}

function sortObjectKeys(object: any) {
  const sorted = Object.keys(object)
    .sort()
    .reduce((accumulator: any, key) => {
      accumulator[key] = object[key];

      return accumulator;
    }, {});
  return sorted;
}

function isArrayOfObjects(value: any) {
  if (Array.isArray(value)) {
    const result = value.some((element) => {
      if (typeof element === "object") {
        return true;
      }
      return false;
    });
    return result;
  }
  return false;
}

export function objectDifference(object: any, base: any) {
  const deletes: any = {};
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value: any, key: any) {
      if (isArrayOfObjects(value) && isArrayOfObjects(base[key])) {
        base[key].forEach((element: any) => {
          if (element._id) element._id = element._id.toString();
        });

        value = value.map((element: any) => sortObjectKeys(element));
        base[key] = base[key].map((element: any) => sortObjectKeys(element));
      }

      if (!isEqual(value, base[key])) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          const baseStringified = JSON.stringify(base[key]);
          const valueIds = value.map((val) => val._id);

          const difference = value.filter((item) => {
            return !baseStringified.includes(JSON.stringify(item));
          });
          const deleted = base[key].filter((item: any) => {
            return !valueIds.includes(item._id);
          });

          if (deleted !== undefined) {
            deletes[key] = deleted;
          }

          result[key] = difference;
        } else {
          result[key] =
            isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      }
    });
  }
  const updates = changes(object, base);
  return { updates, deletes };
}

export function roundToNearest(n: number, step: number) {
  const remainder = n % step;
  if (remainder >= step / 2) {
    return n + (step - remainder);
  } else {
    return n - remainder;
  }
}

export function formatFileSize(bytes: number) {
  if (isNaN(bytes) || bytes === 0) return "0 B";

  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);

  return `${formattedSize} ${sizes[i]}`;
}

export function getRandomId() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}
