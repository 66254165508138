import type { RouteRecordRaw } from "vue-router";

export const assistantRoutes: RouteRecordRaw[] = [
  {
    name: "brain",
    path: "",
    component: () => import("@/views/AssistantView.vue"),
  },
  {
    path: "query",
    children: [
      {
        path: "",
        redirect: {
          name: "brain",
          query: {
            currentTab: "research",
          },
        },
      },
      {
        path: ":id",
        name: "single-research-result",
        component: () => import("@/views/AssistantSingleResearch.vue"),
      },
    ],
  },
  {
    path: ":scope", //account,competitor
    redirect: () => ({ name: "brain", query: { currentTab: "about" } }),
    children: [
      {
        path: ":platform", //website,twitter,facebook,etc
        name: "assistant-platform",
        children: [
          {
            path: "",
            name: "assistant-website",
            component: () => import("@/views/AssistantPlatformDetails.vue"),
          },
          {
            path: ":id",
            name: "assistant-platform-details",
            component: () => import("@/views/AssistantPlatformDetails.vue"),
          },
        ],
      },
    ],
  },
];
