import { createI18n } from "vue3-i18n";
import { strings } from "./strings-autogenerated";
import { temporaryEn } from "./temporary";


declare module "vue" {
  interface ComponentCustomProperties {
    $t: (key: string, replacements?: Record<string, string> | string[]) => string;
  }
}

const messages = { ...strings };
messages.en = Object.assign(messages.en, temporaryEn); 

export const i18n = createI18n({
  locale: "en",
  messages,
});
