// import { randCatchPhrase } from "@ngneat/falso";

import type { SingleRecentHeyConversationDetails } from "@/types/v2";
import type { QueryClient } from "@tanstack/vue-query";
import type { MaybeRef } from "vue";

type MessageSender = SingleRecentHeyConversationDetails["messages"][number]["role"];

export function useV2SingleConversationDetailsRequest(conversationId: MaybeRef<string>) {
  const enabled = computed(() => !!unref(conversationId));
  const request = useQuery({
    queryKey: ["v2", "conversations", "messages", conversationId],
    queryFn: async () => queryFn(unref(conversationId)),
    staleTime: 3 * MINUTE,
    enabled,
  });
  return request;
}

//
// UTILS
//

async function queryFn(conversationId: string) {
  const response = await useGetRequest<SingleRecentHeyConversationDetails>(`/hey`, {
    params: {
      id: conversationId,
    },
  });
  return response?.data || null;
}

export function getCachedConversationDetailsById(id: string, queryClient: QueryClient) {
  return queryClient.getQueryData<SingleRecentHeyConversationDetails>([
    "v2",
    "conversations",
    "messages",
    id,
  ]);
}

export function prefetchSingleMessageDetails(
  conversationId: string,
  queryClient: QueryClient
) {
  return queryClient.prefetchQuery<SingleRecentHeyConversationDetails>({
    queryKey: ["v2", "conversations", "messages", conversationId],
    queryFn: async () => queryFn(conversationId),
  });
}

export function addMessageToConversationDetails(
  conversationId: string,
  message: { role: MessageSender; content: string },
  queryClient: QueryClient
) {
  if (!conversationId) return;
  const existingConversation =
    queryClient.getQueryData<SingleRecentHeyConversationDetails>([
      "v2",
      "conversations",
      "messages",
      conversationId,
    ]);
  if (!existingConversation) return;
  const id = getRandomId();
  const newMessages = [...existingConversation.messages, { id, ...message }];
  queryClient.setQueryData<SingleRecentHeyConversationDetails>(
    ["v2", "conversations", "messages", conversationId],
    {
      ...existingConversation,
      messages: newMessages,
    }
  );
}
