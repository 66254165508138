<template>
  <Menu as="div" class="relative">
    <MenuButton :disabled="isPending" class="font-medium flex gap-1.5 items-center">
      <span>{{ $t("wordsAccounts") }}</span>
      <SpinIcon v-if="isPending" class="h-4 w-4" />
      <ChevronDownIcon v-else class="h-4 w-4" />
    </MenuButton>
    <MenuItems
      as="ul"
      class="absolute right-0 w-[90vw] max-w-[300px] bg-white dark:bg-slate-800 shadow-lg rounded-md border border-slate-200/40 dark:border-slate-700/40 z-[90]"
    >
      <div
        v-if="activeAccount"
        class="flex flex-col py-2.5 px-4 border-b border-slate-200/60 dark:border-slate-700/40 leading-none"
      >
        <p class="text-xs opacity-70">{{ $t("signedInAs") }}</p>
        <p class="flex gap-1 flex-wrap items-center truncate">
          <span class="text-primary">{{ activeAccount.label }}</span>
        </p>
      </div>
      <div
        v-if="!accounts.length && !isOnboarding"
        class="h-28 flex flex-col items-center justify-center"
      >
        <p class="opacity-70">{{ $t("wordsNoAccount") }}</p>

        <MenuItem
          as="button"
          class="mt-3 p-2 px-4 border dark:border-slate-700/40 hover:bg-primary hover:text-white rounded-full"
          @click="goToAccounts"
        >
          <span>{{ $t("wordsManageAccounts") }}</span>
        </MenuItem>
      </div>
      <template v-else-if="!isOnboarding">
        <MenuItem v-for="account in accounts" :key="account.value" v-slot="{ active }">
          <div
            class="flex gap-6 py-2.5 sm:py-3 px-4 duration-200 cursor-pointer"
            role="button"
            :class="{
              'bg-slate-100 dark:bg-slate-100/5': active,
            }"
            @click="!account.isActive && doSwitch(account.value)"
          >
            <div class="left">
              <p>{{ account.label }}</p>
              <p class="text-xs">{{ account.description }}</p>
            </div>
            <div
              class="right h-8 w-8 border dark:border-slate-700/40 ml-auto rounded-full font-semibold inline-grid place-content-center duration-200 delay-100"
              :class="active || account.isActive ? 'bg-primary text-white' : ''"
            >
              <!-- Switch -->
              <SpinIcon
                v-if="isPending && accountToSwitch === account.value"
                class="h-3 w-3"
              />
              <CheckIcon v-else-if="account.isActive" class="h-3 w-3" />
              <ChevronDownIcon v-else class="h-3 w-3 -rotate-90" />
            </div>
          </div>
        </MenuItem>
        <MenuItem
          as="li"
          class="px-4 py-2 cursor-pointer font-semibold text-center hover:bg-slate-100 dark:hover:bg-slate-100/5"
          role="button"
          @click="goToAccounts"
        >
          {{ $t("wordsViewAll") }}
        </MenuItem>
      </template>
      <div
        class="grid divide-x dark:divide-slate-700/40"
        :class="isOnboarding ? 'grid-cols-1' : 'grid-cols-2'"
      >
        <MenuItem
          v-if="!isOnboarding"
          as="button"
          class="w-full flex items-center justify-center gap-0.5 border-t py-2 px-4 dark:border-slate-700/40 opacity-90 hover:opacity-100 hover:text-primary duration-200"
          @click="goToTenantSettings"
        >
          <Cog6ToothIcon class="h-4 w-4" />
          <p>{{ $t("settings.settings") }}</p>
        </MenuItem>
        <MenuItem
          as="button"
          class="border-t w-full flex items-center justify-center gap-2 px-4 opacity-90 hover:opacity-100 text-red-500 dark:border-slate-700/40 duration-200"
          :class="isOnboarding ? 'py-4' : 'py-2'"
          @click="router.push('/logout')"
        >
          <p>{{ $t("auth.signOut") }}</p>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script setup lang="ts">
import type { DropdownOption } from "@/types/formData";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronDownIcon, Cog6ToothIcon, CheckIcon } from "@heroicons/vue/24/outline";
import SpinIcon from "../icons/SpinIcon.vue";

const props = defineProps<{
  accounts: (DropdownOption & { description: string; isActive?: boolean })[];
}>();

const router = useRouter();
const accountToSwitch = ref("");
const { maybeSave, isPending } = useActiveAccountSwitch();

const isOnboarding = computed(() => router.currentRoute.value.path === "/tenant/welcome");

async function doSwitch(id: string) {
  try {
    accountToSwitch.value = id;
    await maybeSave(id);
  } finally {
    accountToSwitch.value = "";
  }
}

function goToAccounts() {
  router.push("/tenant");
}

function goToTenantSettings() {
    router.push("/tenant/settings");
}

const activeAccount = computed(() => {
  return props.accounts.find(account => account.isActive);
});
</script>
