import type { SocialPlatform } from "@/types/account";
import type { AssistantPlatformPages } from "@/types/assistant";

export function getAssistantSocialProfileQueryKey(platform: SocialPlatform, id: string) {
  return ["assistant-social-profile", platform, id];
}
export function useAssistantSocialProfileRequest(id: string, platform: SocialPlatform) {
  const query = useQuery({
    queryKey: getAssistantSocialProfileQueryKey(platform, id),
    queryFn: async () => {
      const response = await useGetRequest<{
        items: AssistantPlatformPages[typeof platform][];
      }>("/account/data", {
        params: {
          itemId: id,
          getSubItems: true,
        },
      });
      return response?.data?.items?.[0] || null;
    },
    staleTime: 3 * MINUTE,
  });

  return query;
}
