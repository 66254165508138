import { createRouter, createWebHistory } from "vue-router";
import { authGuard as _authGuard } from "@auth0/auth0-vue";
import { settingsRoutes } from "./settings";
import { beforeEnterFeatureFlagged } from "./middleware/beforeEnterFeatureFlagged";
import { tenantRoutes } from "./tenant";
import { assistantRoutes } from "./assistant";
import { scheduledRoutes } from "./scheduled";
import Bugsnag from "@bugsnag/js";
import { accessTokenMiddleware } from "./middleware/accessToken";
import { tenantMiddleware } from "./middleware/tenant";

const authGuard = isDESKTOP ? undefined : _authGuard;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/Logout.vue"),
      meta: {
        titleTag: "Logout | Marketing Assistant",
      },
    },
    {
      path: "/init",
      name: "initialize",
      beforeEnter: authGuard,
      component: () => import("@/views/Initialize.vue"),
      meta: {
        titleTag: "Init | Marketing Assistant",
        hideBanners: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        titleTag: "Login | Marketing Assistant",
      },
    },
    {
      path: "/components",
      name: "componentPage",
      beforeEnter: authGuard,
      component: () => import("@/views/ComponentPage.vue"),
      meta: {
        titleTag: "Login | Marketing Assistant",
      },
    },
    {
      path: "/plans",
      name: "plans",
      beforeEnter: authGuard,
      component: () => import("@/views/SelectPlan.vue"),
      meta: {
        titleTag: "Plans | Marketing Assistant",
      },
    },
    {
      path: "/onboarding",
      name: "onboarding",
      beforeEnter: authGuard,
      component: () => import("@/views/OnboardingView.vue"),
      meta: {
        titleTag: "Onboarding | Marketing Assistant",
        hideFooter: true,
      },
    },
    {
      path: "/verify",
      name: "verify-account",
      beforeEnter: authGuard,
      component: () => import("@/views/VerifyAccount.vue"),
      meta: {
        titleTag: "Verify Account | Marketing Assistant",
      },
    },
    {
      path: "/payment-failed",
      name: "payment-failed",
      beforeEnter: authGuard,
      component: () => import("@/views/PaymentFailed.vue"),
      meta: {
        titleTag: "Payment Failed | Marketing Assistant",
      },
    },
    {
      path: "/register",
      name: "register",
      beforeEnter: authGuard,
      component: () => import("@/views/Register.vue"),
      meta: {
        titleTag: "Register | Marketing Assistant",
      },
    },
    /**
     * Tasks
     */
    {
      path: "/tasks",
      redirect: () => ({ name: "task-list", params: { status: "recent" } }),
      beforeEnter: authGuard,
      children: [
        {
          path: ":status",
          name: "task-list",
          component: () => import("../views/TaskList.vue"),
          meta: {
            titleTag: "Tasks List | Marketing Assistant",
          },
        },
      ],
    },

    /**
     * Task
     */
    {
      path: "/task",
      redirect: () => ({ name: "task-list", params: { status: "recent" } }),
      beforeEnter: authGuard,
      children: [
        {
          path: "result/:id",
          name: "single-task",
          beforeEnter: authGuard,
          component: () => import("../views/SingleTaskResult.vue"),
          meta: {
            titleTag: "Task Result | Marketing Assistant",
          },
        },
        {
          path: "create/:category/:type/:key",
          name: "create-task-new",
          beforeEnter: authGuard,
          component: () => import("../views/CreateTask.vue"),
        },

        {
          path: "search",
          name: "search-tasks",
          beforeEnter: authGuard,
          component: () => import("../views/NewTask.vue"),
          meta: {
            titleTag: "Create Task | Marketing Assistant",
          },
        },
        {
          path: "new",
          name: "create-task",
          component: () => import("../views/NewTask.vue"),
          meta: {
            titleTag: "Create Task | Marketing Assistant",
          },
          children: [
            {
              path: ":category",
              name: "single-task-category",
              component: () => import("../views/NewTask.vue"),
              meta: {
                titleTag: "Task Result | Marketing Assistant",
              },
              children: [
                {
                  path: ":type",
                  name: "single-task-type",
                  component: () => import("../views/NewTask.vue"),
                  meta: {
                    titleTag: "Task Result | Marketing Assistant",
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      path: "/messages",
      name: "messages",
      beforeEnter: isDESKTOP
        ? undefined
        : [beforeEnterFeatureFlagged("messagesUi"), _authGuard],
      component: () => import("../views/MessagesView.vue"),
      meta: {
        titleTag: "Messages | Marketing Assistant",
        hideFooter: true,
        hideHeader: false,
        disableScrollToBottom: true,
        hideBanners: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      beforeEnter: authGuard,
      component: () => import("../views/SettingsView.vue"),
      children: settingsRoutes,
      meta: {
        titleTag: "Settings | Marketing Assistant",
      },
    },
    {
      path: "/tenant",
      beforeEnter: authGuard,
      children: tenantRoutes,
      meta: {
        titleTag: "Tenant | Marketing Assistant",
      },
    },
    {
      path: "/third-party",
      name: "third-party",
      component: () => import("../views/ThirdParty.vue"),
      meta: {
        titleTag: "Third-party | Marketing Assistant",
      },
    },
    {
      path: "/my-assistant",
      beforeEnter: authGuard,
      children: assistantRoutes,
      meta: {
        titleTag: "My Assistant | Marketing Assistant",
      },
    },
    {
      path: "/welcome",
      name: "welcome",
      beforeEnter: authGuard,
      component: () => import("../views/WelcomeView.vue"),
      meta: {
        titleTag: "Welcome | Marketing Assistant",
      },
    },
    {
      path: "/home",
      name: "home",
      beforeEnter: authGuard,
      component: () => import("../views/HomeView.vue"),
      meta: {
        titleTag: "Home | Marketing Assistant",
      },
    },
    {
      path: "/scheduled",
      beforeEnter: authGuard,
      meta: {
        titleTag: "Scheduled Activities | Marketing Assistant",
      },
      children: scheduledRoutes,
    },
    {
      path: "/suggestions",
      name: "suggestions",
      beforeEnter: authGuard,
      component: () => import("../views/SuggestionsView.vue"),
      meta: {
        titleTag: "Suggestions | Marketing Assistant",
      },
    },

    {
      path: "/",
      name: "main",
      beforeEnter: authGuard,
      redirect: "/init",
    },
    {
      path: "/error",
      name: "error",
      component: () => import("../views/ErrorView.vue"),
      meta: {
        titleTag: "Error | Marketing Assistant",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: () => import("../views/NotFound.vue"),
    },
  ],
});

router.onError((error, to, from) => {
  const errorMessage = `There was an error going from ${from.path} to ${to.path}`;
  Bugsnag.leaveBreadcrumb(errorMessage);
  Bugsnag.notify(error);
});

router.beforeEach(accessTokenMiddleware);
router.beforeEach(tenantMiddleware);

export default router;
