<template>
  <div>
    <div
      v-if="!jobType"
      :class="[
        wrapperClass,
        isCompetitor
          ? 'bg-surface-200 text-surface-500 dark:bg-surface-700 dark:text-surface-300'
          : 'bg-blue-50 text-blue-500 dark:bg-primary-300/10',
      ]"
    >
      <GlobeAmericasIcon class="h-5 w-5" />
    </div>
    <div
      v-else
      :class="[
        wrapperClass,
        isCompetitor
          ? 'bg-surface-200 text-surface-500 dark:bg-surface-700 dark:text-surface-300'
          : 'bg-blue-50 text-blue-500 dark:bg-primary-300/10',
      ]"
    >
      <FacebookIcon v-if="jobType === 'fb'" class="h-5 w-5" />
      <TwitterIcon v-else-if="jobType === 'tw'" class="h-5 w-5" />
      <LinkedinIcon v-else-if="jobType === 'li'" class="h-5 w-5" />
      <YoutubeIcon v-else-if="jobType === 'yt'" class="h-5 w-5" />
      <InstagramIcon v-else-if="jobType === 'ig'" class="h-5 w-5" />
      <ThreadsIcon v-else-if="jobType === 'th'" class="h-5 w-5" />
      <BoltIcon v-else class="h-5 w-5" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BoltIcon, GlobeAmericasIcon } from "@heroicons/vue/24/solid";
import { type JobNotificationData } from "../../types/jobs";
import FacebookIcon from "../icons/FacebookIcon.vue";
import TwitterIcon from "../icons/TwitterIcon.vue";
import LinkedinIcon from "../icons/LinkedinIcon.vue";
import YoutubeIcon from "../icons/YoutubeIcon.vue";
import InstagramIcon from "../icons/InstagramIcon.vue";
import ThreadsIcon from "../icons/ThreadsIcon.vue";

const wrapperClass = "grid place-content-center rounded-full h-full w-full";

const props = defineProps<{
  notification?: JobNotificationData;
  platform?: "fb" | "tw" | "li" | "yt" | "ig" | "th";
  isCompetitor?: boolean;
}>();

const jobType = computed(() => {
  if (props.platform) return props.platform;
  // @ts-ignore
  const lookup = props.notification?.data?.lookup;
  if (!lookup || typeof lookup !== "string") return "";
  switch (true) {
    case lookup.startsWith("facebook"):
      return "fb";
    case lookup.startsWith("twitter"):
      return "tw";
    case lookup.startsWith("linkedin"):
      return "li";
    case lookup.startsWith("youtube"):
      return "yt";
    case lookup.startsWith("instagram"):
      return "ig";
    case lookup.startsWith("threads"):
      return "th";
    default:
      return "";
  }
});
</script>
