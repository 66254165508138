<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="m14 19l4-4l-1.4-1.4l-1.6 1.55V11h-2v4.15l-1.6-1.55L10 15zm-5-6h2V8.85l1.6 1.55L14 9l-4-4l-4 4l1.4 1.4L9 8.85zm3 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"
    />
  </svg>
</template>

<script setup lang="ts"></script>
