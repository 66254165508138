/**
 * Called in modals and overlays to prevent background content from scrolling while the modal is open. Cleans up before the component is unmounted
 */

export function usePauseScroll() {
  if (!document) return;
  const originalOverflow = document.body.style.overflow;
  function disableScroll() {
    document.body.style.overflow = "hidden";
  }
  function enableScroll() {
    document.body.style.overflow = originalOverflow || "unset";
  }

  onMounted(disableScroll);
  onBeforeUnmount(enableScroll);

  return { disableScroll, enableScroll };
}
