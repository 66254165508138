// import { randCatchPhrase } from "@ngneat/falso";

import type { RecentHeyConversationListItem } from "@/types/v2";

type ResponseType = {
  items: RecentHeyConversationListItem[];
  next: string;
};

export function useRecentV2ConversationList() {
  const request = useInfiniteQuery({
    queryKey: ["v2", "conversations", "recent"],
    queryFn: async ({ pageParam }: { pageParam: string | null } = { pageParam: "" }) => {
      const response = await useGetRequest<ResponseType>("/hey", {
        params: {
          nextQuery: pageParam || undefined,
        },
      });
      return response?.data || null;
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // return getMockData();
    },
    getNextPageParam: lastPage => lastPage.next || null,
    select: data => {
      return data?.pages?.map((p: ResponseType) => p.items)?.flat() || [];
    },
    initialPageParam: "",
    staleTime: 3 * MINUTE,
  });
  return request;
}
