import type { AssistantWebsitePage } from "@/types/assistant";
import type { MaybeRef } from "vue";

export function getAssistantCompetitorPagesQueryKey(id: MaybeRef<string>) {
  return ["assistant-data", "competitor-pages", id];
}

export function useAssistantCompetitorWebsitePagesRequest(websiteId: MaybeRef<string>) {
  const enabled = computed(() => !!unref(websiteId));
  const query = useQuery({
    queryKey: getAssistantCompetitorPagesQueryKey(websiteId),
    queryFn: async () => {
      const response = await useGetRequest<{ items: AssistantWebsitePage[] }>(
        "/account/data",
        {
          params: {
            itemId: unref(websiteId),
            getSubItems: true,
          },
        }
      );
      return response?.data?.items || null;
    },
    select(data) {
      return data?.sort(
        (a, b) => (a.sourceData?.depth || 0) - (b.sourceData?.depth || 1)
      );
    },
    staleTime: 3 * MINUTE,
    enabled: enabled,
  });
  return query;
}
