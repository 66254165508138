import { defaultInputStyles } from "../_utils";

export default {
  root: ({ props, parent }) => ({
    class: [
      // Flex
      "inline-flex",
      { "flex-col": props.showButtons && props.buttonLayout == "vertical" },
      { "flex-1 w-[1%]": parent.instance.$name == "InputGroup" },

      //Sizing
      { "!w-16": props.showButtons && props.buttonLayout == "vertical" },

      // Shape
      {
        "first:rounded-l-md rounded-none last:rounded-r-md":
          parent.instance.$name == "InputGroup" && !props.showButtons,
      },
      {
        "border-0 border-y border-l last:border-r border-surface-300 dark:border-surface-600":
          parent.instance.$name == "InputGroup" && !props.showButtons,
      },

      {
        "ring-1 ring-surface-300 dark:ring-surface-700 ring-offset-0":
          parent.instance.$name !== "InputGroup",
      },
      "shadow-sm",
      "rounded-md",
    ],
  }),
  input: {
    root: ({ parent, props }) => ({
      class: [
        ...defaultInputStyles({
          invalid: parent.props.invalid,
          disabled: parent.props.disabled,
          size: props.size,
        }),

        {
          "text-center":
            parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        { "rounded-tr-none rounded-br-none": parent.props.showButtons },
        {
          "rounded-tl-none rounded-bl-none":
            parent.props.showButtons && parent.props.buttonLayout === "horizontal",
        },
        {
          "rounded-none":
            parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        {
          "border-r": parent.props.showButtons && parent.props.buttonLayout == "stacked",
        },
        {
          "border-x":
            parent.props.showButtons && parent.props.buttonLayout == "horizontal",
        },
        {
          "border-y": parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        // States
        "outline-none focus:ring-primary-500 dark:focus:ring-primary-400",
        "focus:outline-none border-0 focus:outline-offset-0 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400",

        // Filled State *for FloatLabel
        {
          filled:
            parent.instance?.$parentInstance?.$name == "FloatLabel" &&
            parent.state.d_modelValue !== null,
        },

        //Position
        {
          "order-2":
            parent.props.buttonLayout == "horizontal" ||
            parent.props.buttonLayout == "vertical",
        },
      ],
    }),
  },

  buttongroup: {
    class: ["flex", "flex-col"],
  },

  incrementbutton: {
    root: ({ parent }) => ({
      class: [
        // Display
        "flex flex-auto",

        // Alignment
        "items-center",
        "justify-center",
        "text-center align-bottom",

        //Position
        "relative",
        {
          "order-3":
            parent.props.showButtons && parent.props.buttonLayout == "horizontal",
        },
        {
          "order-1": parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        //Color
        "text-surface-600 dark:text-surface-400",
        "bg-surface-0 dark:bg-surface-800",

        // Sizing
        "w-[3rem]",
        {
          "px-2.5 py-1.5":
            parent.props.showButtons && parent.props.buttonLayout !== "stacked",
        },
        { "p-0": parent.props.showButtons && parent.props.buttonLayout == "stacked" },
        { "w-full": parent.props.showButtons && parent.props.buttonLayout == "vertical" },

        // Shape
        "rounded-md",
        {
          "rounded-tl-none rounded-br-none rounded-bl-none":
            parent.props.showButtons && parent.props.buttonLayout == "stacked",
        },
        {
          "rounded-bl-none rounded-tl-none":
            parent.props.showButtons && parent.props.buttonLayout == "horizontal",
        },
        {
          "rounded-bl-none rounded-br-none":
            parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        //States
        // "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-600 focus:dark:ring-primary-500",
        "hover:bg-surface-50 dark:hover:bg-surface-700",

        //Misc
        "cursor-pointer overflow-hidden select-none",
      ],
    }),
    label: {
      class: "h-0 w-0",
    },
  },
  decrementbutton: {
    root: ({ parent }) => ({
      class: [
        // Display
        "flex flex-auto",

        // Alignment
        "items-center",
        "justify-center",
        "text-center align-bottom",

        //Position
        "relative",
        {
          "order-1":
            parent.props.showButtons && parent.props.buttonLayout == "horizontal",
        },
        {
          "order-3": parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        //Color
        "text-surface-600 dark:text-surface-400",
        "bg-surface-0 dark:bg-surface-800",

        // Sizing
        "w-[3rem]",
        {
          "px-2.5 py-1.5":
            parent.props.showButtons && parent.props.buttonLayout !== "stacked",
        },
        { "p-0": parent.props.showButtons && parent.props.buttonLayout == "stacked" },
        { "w-full": parent.props.showButtons && parent.props.buttonLayout == "vertical" },

        // Shape
        "rounded-md",
        {
          "rounded-tr-none rounded-tl-none rounded-bl-none":
            parent.props.showButtons && parent.props.buttonLayout == "stacked",
        },
        {
          "rounded-tr-none rounded-br-none ":
            parent.props.showButtons && parent.props.buttonLayout == "horizontal",
        },
        {
          "rounded-tr-none rounded-tl-none ":
            parent.props.showButtons && parent.props.buttonLayout == "vertical",
        },

        //States
        // "focus:outline-none focus:outline-offset-0  focus:ring-primary-500 focus:dark:ring-primary-400",
        // "hover:bg-surface-50 dark:hover:bg-surface-700",

        //Misc
        "cursor-pointer overflow-hidden select-none",
      ],
    }),
    label: {
      class: "h-0 w-0",
    },
  },
};
