import { defaultInputStyles } from "../_utils";

export default {
  root: ({ props, context, parent }) => ({
    class: [
      "ma-input",

      ...defaultInputStyles({
        invalid: props.invalid,
        disabled: props.disabled,
        size: props.size,
      }),

      { "flex-1 w-[1%]": parent.instance.$name == "InputGroup" },
      { "rounded-none": parent.instance.$name === "InputGroup" },
      {
        "first:rounded-l-md rounded-none last:rounded-r-md":
          parent.instance.$name === "InputGroup",
      },
      {
        "border-0 border-y border-l last:border-r border-surface-200 dark:border-surface-600":
          parent.instance.$name === "InputGroup",
      },
      {
        "first:ml-0 -ml-px": parent.instance.$name == "InputGroup" && !props.showButtons,
      },
      "appearance-none",

      // Filled State *for FloatLabel
      { filled: parent.instance?.$name == "FloatLabel" && context.filled },
    ],
  }),
};
