import { useToast } from "vue-toast-notification";

export function useActiveAccountSwitch() {
  const $toast = useToast();
  const { t } = withI18nHelpers();
  const { mutateAsync, isPending, isError, error } = useAccountUserUpdate();

  function preventMouseEvents() {
    document.body.style.pointerEvents = "none";
    document.body.style.userSelect = "none";
  }

  function allowMouseEvents() {
    document.body.style.pointerEvents = "auto";
    document.body.style.userSelect = "auto";
  }

  async function maybeSave(accountId: string) {
    try {
      if (isPending.value) return;
      preventMouseEvents();
      await mutateAsync({ activeAccountId: accountId });
      if (isError.value) {
        $toast.error(error.value?.message || t("errors.unknown"), defaultToastOptions);
        return;
      }
      sessionStorage.setItem("MM-accountSwitched", "1");
      sessionStorage.setItem("accountSwitched", "1");
      if (import.meta.env.MODE === "local-test") {
        return;
      }
      if (isDESKTOP) {
        await window.electronAPI?.reloadPage();
        return;
      }
      window.location.reload();
    } finally {
      allowMouseEvents();
    }
  }

  return {
    maybeSave,
    isPending,
  };
}
