import type { AnyFn } from "@vueuse/core";
import { handleInitError } from "./error";

export async function bootstrapAppWithEnvVariables(cb: AnyFn) {
  try {
    useAppInit().startInitializing();
    // comment out to use remote env locally
    if (import.meta.env.DEV) {
      for (const key in import.meta.env) {
        env[key] = `${import.meta.env[key]}`;
      }
      cb();
      return;
    }
    const url = getActiveEnvironmentUrl();
    const res = await fetch(url);
    const data: Record<string, string> = await res.json();
    setEnvVariables(data);
    cb();
  } catch (e) {
    handleInitError();
    console.error(e);
  }
}

function getActiveEnvironmentUrl() {
  const hostName = window.location.hostname;
  const BASE_CONFIG_URL = "https://assets.marketingassistant.com/configs";

  const envMap: Record<string, string> = {
    localhost: "env.staging-gifty.json",
    "d25xpy4wjfyth6.cloudfront.net": "env.marketing-tests.json",
    "dbdli8bn2i0o3.cloudfront.net": "env.staging-gifty.json",
    "staging.marketingassistant.com": "env.staging.json",
    "staging-app.marketingassistant.com": "env.staging.json",
    "app.marketingassistant.com": "env.production.json",
    "marketingassistant.com": "env.production.json",
    "d112oo1k3k16of.cloudfront.net": "env.staging-gordon.json",
  };

  const fileName = envMap[hostName];
  if (!fileName) throw new Error("Unknown environment");
  const url = `${BASE_CONFIG_URL}/${fileName}`;
  return url;
}

function setEnvVariables(object: Record<string, string>) {
  const keys = Object.keys(object);
  for (const key of keys) {
    const value = object[key] ?? ""; //allow 0
    env[key] = `${value}`;
  }
}
