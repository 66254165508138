import type { SocialPlatform } from "@/types/account";
import type { AssistantJobSocketData } from "@/types/jobs";
import type { QueryClient } from "@tanstack/vue-query";

/**
 * Invalidates assistant queries based on job type
 * @param data Job data
 * @param queryClient tanstack queryClient instance
 * @returns void
 */
export function handleDiscoveryJob(
  data: AssistantJobSocketData,
  queryClient: QueryClient
) {
  try {
    //
    // reused helpers
    //
    const assistantHomeSummaryKey = getAssistantResourceSummaryQueryKey();
    const source = data.data.source;
    const isMainAccountWebsite =
      data.jobType === "process_website" && source === "account.website";

    const isCompetitorWebsite =
      data.jobType === "process_website" && data.data.isCompetitor;

    const isCompetitorComplete = data.jobType === "account_discover_competitor";

    const isMainAccountSocialMedia =
      data.jobType === "ProcessSocialMedia" &&
      !data.data.isCompetitor &&
      socialPlatformArr.includes(data.data.source as SocialPlatform);

    const isCompetitorSocialMedia =
      data.jobType === "ProcessSocialMedia" && data.data.isCompetitor;

    if (data.status === "running") return;

    //
    // Job Handlers
    //

    // competitors
    if (isCompetitorWebsite || isCompetitorComplete || isCompetitorSocialMedia)
      handleCompetitorJobs(data, queryClient);

    // website
    if (isMainAccountWebsite) handleMainWebsiteAccountJobs(queryClient);

    // Account socials
    if (isMainAccountSocialMedia) handleMainAccountSocialJobs(data, queryClient);

    // summary
    if (
      isMainAccountWebsite ||
      isMainAccountSocialMedia ||
      isCompetitorComplete ||
      isCompetitorWebsite
    ) {
      if (data.status === "failed") return;
      queryClient.invalidateQueries({
        queryKey: assistantHomeSummaryKey,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function handleMainWebsiteAccountJobs(queryClient: QueryClient) {
  // website
  queryClient.invalidateQueries({
    queryKey: getAssistantWebsitePagesQueryKey(),
  });
  // pages
  queryClient.invalidateQueries({
    queryKey: getAssistantWebsiteDataQueryKey(),
  });
  // processing state
  queryClient.invalidateQueries({
    queryKey: getAssistantResourceStateKey("website"),
  });
}

function handleMainAccountSocialJobs(
  data: AssistantJobSocketData,
  queryClient: QueryClient
) {
  const platform = data.data.source as SocialPlatform;
  // profile
  queryClient.invalidateQueries({
    queryKey: getAssistantSocialProfileQueryKey(
      platform,
      `${platform}#${platform}#profile`
    ),
  });
  // posts
  queryClient.invalidateQueries({
    queryKey: getAssistantsPostsQueryKey(platform, `${platform}#${platform}#post`),
  });

  // processing state
  queryClient.invalidateQueries({
    queryKey: getAssistantResourceStateKey(platform),
  });

  // reels
  if (platform === "facebook") {
    queryClient.invalidateQueries({
      queryKey: getAssistantsPostsQueryKey(platform, `${platform}#${platform}#reel`),
    });
  }
}

function handleCompetitorJobs(data: AssistantJobSocketData, queryClient: QueryClient) {
  const ids = data.data.accountDataItemIds || [];
  const rootId = ids[0]?.slice(0, ids[0].lastIndexOf("#"));
  // website
  if (rootId) {
    // landing page
    queryClient.invalidateQueries({
      queryKey: getAssistantSingleCompetitorQueryKey(rootId + "#website"),
    });
    // website
    queryClient.invalidateQueries({
      queryKey: getAssistantCompetitorPagesQueryKey(rootId + "#website"),
    });
  }
  // social
  for (const id of ids) {
    // posts
    const platform = getLastArrayItem(id.split("#")) as SocialPlatform;
    queryClient.invalidateQueries({
      queryKey: getAssistantsPostsQueryKey(platform, id + "#post"),
    });
    // profile
    queryClient.invalidateQueries({
      queryKey: getAssistantSocialProfileQueryKey(platform, id + "#profile"),
    });
    // reels
    if (platform === "facebook") {
      queryClient.invalidateQueries({
        queryKey: getAssistantsPostsQueryKey(platform, `${id}#reel`),
      });
    }
    // processing state
    queryClient.invalidateQueries({
      queryKey: getAssistantResourceStateKey(id as `competitorWebsite#${string}`),
    });
  }
}
