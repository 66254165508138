<template>
  <div class="flex items-center justify-end px-4 py-2 border-b dark:border-slate-700">
    <div class="w-full max-w-[calc(100%-4rem)] flex justify-end gap-4">
      <!-- Preview -->

      <div
        v-if="lastMessage?.statement && !v2ResponseStore.isPreviewDismissed"
        class="w-full flex items-center justify-end gap-2 opacity-0 animate-[fade-in-up_0.2s_forwards]"
        :key="lastMessage.sender"
      >
        <button
          v-if="lastMessage.sender === 'assistant'"
          class="py-1.5 px-2.5 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-slate-700 opacity-60 hover:opacity-100 duration-200 rounded-full grid place-content-center text-xs font-medium"
          @click="v2ResponseStore.dismissPreview"
        >
          {{ $t("wordsDismiss") }}
        </button>
        <div
          class="flex items-center gap-2 opacity-80 hover:opacity-100 hover:text-primary duration-200 bg-primary-50 dark:bg-primary-300/10 py-2 px-4 sm:pr-6 rounded-lg w-full sm:w-max sm:max-w-[calc(100%-4rem)] overflow-hidden"
          role="button"
          @click="v2ResponseStore.showResponseOverlay"
        >
          <p class="text-sm truncate max-w-[65ch]">{{ lastMessage.statement }}</p>
        </div>
      </div>
      <!-- Preview end -->
      <!-- processing -->
      <span
        v-if="v2InputStore.state.saving"
        class="flex items-center gap-2 text-sm opacity-0 animate-[fade-in-up_0.2s_0.3s_forwards]"
      >
        <span>{{ $t("processing") }}</span>
        <BotBusyAnimation />
      </span>
      <!-- processing end -->
    </div>

    <button
      class="ml-4 bg-primary relative p-2 text-white rounded-full"
      @click="showResponseOverlay"
    >
      <span
        v-if="v2ResponseStore.unreadCount"
        class="absolute -top-1 -right-1 text-xs text-white bg-red-500 rounded-full px-1.5 py-0.5"
      >
        {{ v2ResponseStore.unreadCount }}
      </span>
      <ChatBubbleOvalLeftIcon class="h-5 w-5" />
    </button>
  </div>
</template>

<script setup lang="ts">
import BotBusyAnimation from "@/components/shared/BotBusyAnimation.vue";
import { ChatBubbleOvalLeftIcon } from "@heroicons/vue/24/outline";

const v2ResponseStore = useV2ResponseStore();
const v2InputStore = useV2InputStore();
const route = useRoute();
const router = useRouter();

const lastMessage = computed(() => {
  return v2ResponseStore.lastMessage;
});

function showResponseOverlay() {
  if (v2ResponseStore.lastConversationId) {
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        activeConversationId: v2ResponseStore.lastConversationId,
      },
    });
  }
  v2ResponseStore.showResponseOverlay();
}

watch(
  () => route.path,
  () => {
    const now = Date.now();
    if (v2ResponseStore.isPreviewDismissed || !v2ResponseStore.lastPreviewUpdate) return;
    const diff = now - v2ResponseStore.lastPreviewUpdate;
    if (diff < MESSAGE_PREVIEW_DURATION) return;
    v2ResponseStore.dismissPreview();
  }
);
</script>

<style scoped></style>
