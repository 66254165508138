import { get } from "lodash-es";

import type { UserAccountDetails } from "@/types/account";
export function useAccountUpdate() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["account", "update"],
    mutationFn: async (input: any) => {
      const response = await usePutRequest<UserAccountDetails | { message: string }>(
        "/account",
        input
      );
      return get(response, "data", null);
    },
    onSuccess: async data => {
      const oldData = queryClient.getQueryData(["account"]);
      if (!oldData || !data) return;
      queryClient.setQueryData(["account"], { ...oldData, ...data });
    },
  });
  return mutation;
}
