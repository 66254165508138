<template>
  <footer class="border-t border-slate-200 dark:border-slate-800">
    <div class="py-8">
      <div class="text-center md:flex md:items-center md:justify-between">
        <!-- Copyright -->
        <div class="flex flex-col sm:flex-row gap-2">
          <div class="text-sm text-slate-500 dark:text-slate-400">
            {{ $t("wordsCopyright") }} &copy; {{ year }}.
            {{ $t("wordsAllRightsReserved") }}
          </div>
        </div>
        <div class="flex justify-center items-center gap-x-2 text-xs">
          <div>v {{ session.appVersion }}</div>
          <div id="teleport-connection"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const session = currentSessionInformation;
const emit = defineEmits(["ready"]);

const year = new Date().getFullYear();

onMounted(() => {
  nextTick(() => {
    emit("ready");
  });
});
</script>
