import { get } from "lodash-es";

import type { MutationResult } from "@/types/tanstack";
import type { UseMutationReturnType } from "@tanstack/vue-query";
import type { UserAccountUpdate, UserAccountDetails } from "@/types/account";

export function useAccountUserUpdate(): UseMutationReturnType<
  UserAccountDetails | { message: string } | null,
  Error,
  UserAccountUpdate,
  unknown,
  MutationResult<
    UserAccountDetails | { message: string } | null,
    Error,
    UserAccountUpdate,
    unknown
  >
> {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["account", "user", "update"],
    onSuccess: async newData => {
      await queryClient.invalidateQueries({ queryKey: ["account"] });

      const accountDataKey = ["account"];
      const oldData = queryClient.getQueryData<UserAccountDetails>(accountDataKey);

      if (!oldData) {
        return;
      }

      queryClient.setQueryData<UserAccountDetails>(accountDataKey, () => {
        return Object.assign(oldData, newData);
      });
    },
    mutationFn: async (update: UserAccountUpdate) => {
      const response = await usePutRequest<UserAccountDetails | { message: string }>(
        "/account/user",
        update
      );

      if (import.meta.env.MODE === "local-test") {
        const data = response.data as any;
        if (data.token) {
          localStorage.setItem("appAuthToken", data.token);
          currentSessionInformation.appAuthToken = data.token;
        }
      }

      return get(response, "data", null);
    },
    async onMutate(data: UserAccountUpdate) {
      await queryClient.cancelQueries({ queryKey: ["account"] });
      const oldData = queryClient.getQueryData<UserAccountDetails>(["account"]) || {};
      const newData = Object.assign({}, oldData, data);
      console.log(newData);
      queryClient.setQueryData(["account"], newData);
      return { oldData };
    },
    onError(_err, newData, context) {
      if (context) {
        // don't reset if user was changing language
        const keys = Object.keys(newData);
        if (keys?.length === 1 && keys[0] === "locale") return;
        queryClient.setQueryData(["account"], context.oldData);
      }
    },
  });

  return mutation;
}
