<template>
  <div
    class="p-2 px-4 rounded-xl max-w-[80%] relative"
    :class="{
      'bg-primary-600 dark:bg-[#2576a2] text-white mr-auto': sender === 'assistant',
      'bg-white dark:bg-slate-600 ml-auto': sender === 'user',
    }"
  >
    <slot>
      <span>{{ message }}</span>
    </slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  message: string;
  sender: "assistant" | "user";
  isLast?: boolean;
}>();

const emit = defineEmits(["ready"]);

onMounted(() => {
  if (props.isLast) {
    nextTick(() => {
      emit("ready");
    });
  }
});
</script>
