import type { RouteRecordRaw } from "vue-router";
import { beforeEnterFeatureFlagged } from "./middleware/beforeEnterFeatureFlagged";

export const settingsRoutes: RouteRecordRaw[] = [
  {
    name: "Settings",
    path: "",
    component: () => import("@/partials/settings/AccountPanel.vue"),
    meta: {
      titleTag: "Settings | Marketing Assistant",
    },
  },

  {
    name: "Connected Apps",
    path: "apps",
    component: () => import("@/partials/settings/AppsPanel.vue"),
    meta: {
      titleTag: "Settings | Marketing Assistant",

      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: "Integrations",
    path: "integrations",
    component: () => import("@/partials/settings/IntegrationsPanel.vue"),
    meta: {
      titleTag: "Integrations",
    },
  },
  {
    name: "Account Users",
    path: "users",
    component: () => import("@/partials/settings/AccountUsers.vue"),
    beforeEnter: [beforeEnterFeatureFlagged("multiUser")],
    meta: {
      titleTag: "Account Users | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: "notifications",
    path: "notifications",
    component: () => import("@/partials/settings/NotificationsPanel.vue"),
    meta: {
      titleTag: "Notifications | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
  {
    name: "billing",
    path: "billing",
    component: () => import("@/partials/settings/BillingPanel.vue"),
    meta: {
      titleTag: "Plan & Billing | Marketing Assistant",
      breadcrumbs: [
        {
          link: "/settings",
          title: "Settings",
        },
      ],
    },
  },
];
