import "vue-skeletor/dist/vue-skeletor.css";
import "./assets/style.css";
import "./assets/main.scss";
import "vue-toast-notification/dist/theme-default.css";
import "primeicons/primeicons.css";
import App from "./App.vue";

import { version } from "../package.json";
import { MotionPlugin } from "@vueuse/motion";
import MasonryWall from "@yeger/vue-masonry-wall";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createAuth0 } from "@auth0/auth0-vue";
import router from "./router";
import { createGtm } from "@gtm-support/vue-gtm";

import { i18n } from "./strings/i18n";
import { VueQueryPlugin } from "@tanstack/vue-query";
import PrimeVue from "primevue/config";
import Wind from "@/components/theme/presets/wind";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { uninstallServiceWorker } from "./modules/pwa";
import Tooltip from "primevue/tooltip";

import MarkdownText from "@/components/shared/MarkdownText.vue";
import MAInput from "@/components/primevueComponents/MAInput.vue";
import AppButton from "./components/shared/AppButton.vue";
import { bootstrapAppWithEnvVariables } from "./bootstrap";
import HelpButton from "./components/help/HelpButton.vue";

// uninstall sw before attempting to start app
uninstallServiceWorker();

/**
 * Gets env variables on startup before initializing vue app
 */
bootstrapAppWithEnvVariables(() => {
  const app = createApp(App);
  const store = createPinia();
  // router.isReady().then(() => handlePwaUpdates());

  app.use(PrimeVue, { unstyled: true, pt: Wind });
  app.use(i18n);
  app.use(VueQueryPlugin);
  app.use(MotionPlugin);
  app.use(router);
  app.use(store);
  app.use(MasonryWall); // ~1.6kb
  app.directive("tooltip", Tooltip);

  app.component("MarkdownText", MarkdownText);
  app.component("MAInput", MAInput);
  app.component("AppButton", AppButton);
  app.component("HelpButton", HelpButton);

  if (env.VITE_BUGSNAG_KEY && !import.meta.env.DEV) {
    Bugsnag.start({
      apiKey: env.VITE_BUGSNAG_KEY,
      plugins: [new BugsnagPluginVue()],
      releaseStage: env.VITE_NODE_ENV,
      enabledReleaseStages: ["production", "staging"],
      appVersion: version,
    });

    BugsnagPerformance.start({ apiKey: env.VITE_BUGSNAG_KEY });

    const bugsnagVue = Bugsnag.getPlugin("vue");
    if (bugsnagVue) {
      app.use(bugsnagVue);
    }
  }

  if (env.VITE_GOOGLE_TAG_MANAGER_ID && !import.meta.env.DEV) {
    app.use(
      createGtm({
        id: env.VITE_GOOGLE_TAG_MANAGER_ID,
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: !import.meta.env.PROD, // Whether or not display console logs debugs (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: ["initialize"], // Don't trigger events for specified router names (optional)
      })
    );
  }

  const plugin = createAuth0({
    domain: env.VITE_AUTH0_DOMAIN,
    clientId: env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
  });
  if (import.meta.env.MODE === "local-test") {
    plugin.isAuthenticated = { value: true } as any;
    plugin.getAccessTokenSilently = async () => {
      const appAuthToken = localStorage.getItem("appAuthToken");
      if (appAuthToken) {
        return appAuthToken;
      }

      const accountProperties = localStorage.getItem("accountProperties");
      const data = {
        accountProperties: accountProperties?.split(","),
      };
      const overrides = localStorage.getItem("overrides");

      if (overrides) {
        Object.assign(data, { overrides: JSON.parse(overrides) });
      }

      const response = await usePutRequest(
        `${env.VITE_APP_API_URL}/test-setup-user`,
        data
      );

      localStorage.setItem("appAuthToken", response.data[0].token);
      localStorage.setItem("accountId", response.data[0].id);

      return response.data[0].token;
    };
  }
  app.use(plugin);

  app.mount("#app");
});
