import type {  TaskName, TaskType } from "@/types/task";
import { camelCase, startCase } from "lodash-es";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;

export const taskTypes: {
  title: string;
  description: string;
  type: TaskType;
  key: string;
  icon?: any;
  isSingle?: boolean;
}[] = [
  {
    key: formatTaskNames("WriteInstagramPost"),
    title: "Instagram Post",
    description: "Generates posts for instagram based on the content provided",
    type: "WRITE_INSTAGRAM_POST",
    icon: defineAsyncComponent(() => import("@/components/icons/InstagramIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("WriteTwitterPost"),
    title: "Twitter(X) Post",
    description: "Generates posts for twitter based on the content provided",
    type: "WRITE_TWITTER_POST",
    icon: defineAsyncComponent(() => import("@/components/icons/TwitterIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("WriteLinkedinPost"),
    title: "LinkedIn Post",
    description: "Generates posts for linkedin based on the content provided",
    type: "WRITE_LINKEDIN_POST",
    icon: defineAsyncComponent(() => import("@/components/icons/LinkedinIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("WriteFacebookPost"),
    title: "Facebook Post",
    description: "Generates posts for facebook based on the content provided",
    type: "WRITE_FACEBOOK_POST",
    icon: defineAsyncComponent(() => import("@/components/icons/FacebookIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("GenerateImage"),
    title: "Generate Image",
    description: "Generates image based on the content provided",
    type: "GENERATE_IMAGE",
    icon: defineAsyncComponent(() => import("@/components/icons/ImageIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("ReviewWebpage"),
    title: "Review Webpage",
    description:
      "Identifies the following duplicate sections, grammar mistakes, capitalization issues, spelling issues and overall feedback in the provided web page",
    type: "REVIEW_WEBPAGE",
    icon: defineAsyncComponent(() => import("@/components/icons/WebIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("MetaContentGenerator"),
    title: "Generate Meta Content",
    description:
      "Generates title tags and description for the provided web page's meta content",
    type: "META_CONTENT_GENERATOR",
    icon: defineAsyncComponent(() => import("@/components/icons/PageMetaIcon.vue")),
  },
  {
    key: formatTaskNames("MarketArticleIdentifyKeywords"),
    title: "SEO Identify Keywords",
    description: "Generates keyword ideas for the web page provided in the request",
    type: "MARKET_ARTICLE_IDENTIFY_KEYWORDS",
    icon: defineAsyncComponent(() => import("@/components/icons/SeoIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("ResearchV2"),
    title: "Research",
    description: "Returns notes for the topic provided in the request",
    type: "RESEARCH_V_2",
    icon: defineAsyncComponent(() => import("@/components/icons/SeoIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("SearchGoogle"),
    title: "Search Google",
    description: "Returns google search results for the topic provided in the request",
    type: "SEARCH_GOOGLE",
    icon: defineAsyncComponent(() => import("@/components/icons/SeoIcon.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("Run"),
    title: "Run",
    description:
      "Consists of different tasks Marketer Pick Topics, Prepare Initial Research, ResearchV2, Market Article Identify Keywords, Write Post, Meta Content Generator Step1, Write All Social Posts",
    type: "RUN",
    icon: defineAsyncComponent(() => import("@/components/icons/RunIcon.vue")),
  },
  {
    key: formatTaskNames("MarketerPickTopics"),
    title: "MARKETER_PICK_TOPICS",
    description: "",
    type: "MARKETER_PICK_TOPICS",
    icon: defineAsyncComponent(() => import("@/components/icons/MarketerPickTopics.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("WriteAllSocialPosts"),
    title: "WRITE_ALL_SOCIAL_POSTS",
    description: "",
    type: "WRITE_ALL_SOCIAL_POSTS",
    icon: defineAsyncComponent(() => import("@/components/icons/SocialPosts.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("WritePost"),
    title: "WRITE_POST",
    description: "",
    type: "WRITE_POST",
    icon: defineAsyncComponent(() => import("@/components/icons/WritePost.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("PrepareInitialResearch"),
    title: "PREPARE_INITIAL_RESEARCH",
    description: "",
    type: "PREPARE_INITIAL_RESEARCH",
    icon: defineAsyncComponent(() => import("@/components/icons/InitialResearch.vue")),
    isSingle: true,
  },
  {
    key: formatTaskNames("MetaContentGeneratorStep1"),
    title: "Generate Meta Content Step1",
    description:
      "Generates title tags and description for the provided web page's meta content",
    type: "META_CONTENT_GENERATOR_STEP_1",
    icon: defineAsyncComponent(() => import("@/components/icons/PageMetaIcon.vue")),
    isSingle: true,
  },
];

export const singleTaskOptionKeys = [
  "WRITE_FACEBOOK_POST",
  "WRITE_INSTAGRAM_POST",
  "WRITE_LINKEDIN_POST",
  "MARKET_ARTICLE_IDENTIFY_KEYWORDS",
  "WRITE_TWITTER_POST",
  "GENERATE_IMAGE",
  "REVIEW_WEBPAGE",
];
export const groupedTaskOptionKeys = ["RUN", "META_CONTENT_GENERATOR", "RESEARCH_V_2", "WRITE_ALL_SOCIAL_POSTS"];

export function restoreTaskName(taskName: string) {
  if (!taskName || typeof taskName !== "string") return "";
  if(taskName.split("/").length > 1) return taskName;
  return startCase(camelCase(taskName)).replace(/ /g, "") as TaskName;
}

export function getTaskIcon(type?: TaskType){
  const task = taskTypes.find(a => a.type === type);
  return task?.icon || "";
}

export const defaultToastOptions = {
  position: "top" as "top",
  duration: 3000,
  dismissible: true,
};

export const ratings = {
  thumbsUpValue: 5,
  thumbsDownValue: 1,
  noRating: 0,
} as const;

export const languageOptions = [
  {
    label: "English",
    short: "🇺🇸",
    value: "en",
  },
  {
    label: "Español",
    short: "🇪🇸",
    value: "es",
  },
];
