<template>
  <AppButton
    icon="pi pi-question-circle"
    text
    variant="secondary"
    @click="helpStore.openHelpSidebar(helpKey)"
  />
</template>

<script setup lang="ts">
import type { HelpDataKey } from "@/stores/help/data";

defineProps<{
  helpKey: HelpDataKey;
}>();
const helpStore = useAppHelpStore();
</script>
