import type { IScheduledTask, ITask } from "@/types/task";

export function formatTaskNames(taskName: string) {
  let result = "";

  for (let i = 0; i < taskName.length; i++) {
    const currentChar = taskName.charAt(i);

    if (i > 0 && currentChar === currentChar.toUpperCase()) {
      result += "_";
    }

    result += currentChar.toUpperCase();
  }

  return result.replace(" ", "_");
}
export function formatScheduledTaskStatusName(
  task?: IScheduledTask
): { label: string; type: "success" | "danger" | "default" } | null {
  if (!task) return null;
  const { status } = task;
  switch (true) {
    case status === "complete":
      return {
        type: "success",
        label: "wordsComplete",
      };
    case status === "error":
      return {
        type: "danger",
        label: "wordsError",
      };
    case status === "running":
      return {
        type: "default",
        label: "wordsRunning",
      };
    case status === "scheduled":
      return {
        type: "default",
        label: "wordsScheduled",
      };
    case status === "disabled":
      return {
        type: "danger",
        label: "wordsDisabled",
      };
    default:
      return null;
  }
}

export function formatTaskStatusName(
  task?: ITask
): { label: string; type: "success" | "danger" | "default" } | null {
  if (!task) return null;
  switch (true) {
    case !!task.complete:
      return {
        type: "success",
        label: "wordsComplete",
      };
    case !!task.error:
      return {
        type: "danger",
        label: "wordsError",
      };
    case !!task.canceled:
      return {
        type: "danger",
        label: "wordsCanceled",
      };
    case !!task.running:
      return {
        type: "default",
        label: "wordsRunning",
      };
    case !!task.queued:
      return {
        type: "default",
        label: "wordsQueued",
      };
    default:
      return null;
  }
}
