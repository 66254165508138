import type { AnyFn } from "@vueuse/core";
import html2Canvas from "html2canvas";

export const dragEvents = {
  mouse: {
    start: "mousedown",
    move: "mousemove",
    stop: "mouseup",
  },
  touch: {
    start: "touchstart",
    move: "touchmove",
    stop: "touchend",
  },
};

export function isFunction(func: any) {
  return (
    typeof func === "function" ||
    Object.prototype.toString.call(func) === "[object Function]"
  );
}

export function restrictToBounds(value: number, min: number | null, max: number | null) {
  if (min !== null && value < min) {
    return min;
  }

  if (max !== null && max < value) {
    return max;
  }

  return value;
}

export function getComputedSize($el: HTMLElement) {
  const style = window.getComputedStyle($el);

  return [
    parseFloat(style.getPropertyValue("width")) || 0,
    parseFloat(style.getPropertyValue("height")) || 0,
  ];
}

export function addEvent(el: HTMLElement | any, event: string, handler: AnyFn) {
  if (!el) {
    return;
  }
  if (el.attachEvent) {
    el.attachEvent("on" + event, handler);
  } else if (el.addEventListener) {
    el.addEventListener(event, handler, true);
  } else {
    const e = ("on" + event) as keyof HTMLElement;
    el[e] = handler;
  }
}

function isElement(obj: any) {
  return typeof HTMLElement === "object"
    ? obj instanceof HTMLElement
    : obj &&
        typeof obj === "object" &&
        obj !== null &&
        obj.nodeType === 1 &&
        typeof obj.nodeName === "string";
}

export function getWindowScrollTop() {
  const doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}

export function getWindowScrollLeft() {
  const doc = document.documentElement;
  return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
}

export function getAttribute(element: HTMLElement, name: string) {
  if (isElement(element)) {
    const value = element.getAttribute(name);

    if (value && !isNaN(+value)) {
      return +value;
    }

    if (value === "true" || value === "false") {
      return value === "true";
    }

    return value;
  }

  return undefined;
}

export function removeEvent(el: HTMLElement | any, event: string, handler: AnyFn) {
  if (!el) {
    return;
  }
  if (el.detachEvent) {
    el.detachEvent("on" + event, handler);
  } else if (el.removeEventListener) {
    el.removeEventListener(event, handler, true);
  } else {
    el["on" + event] = null;
  }
}

export function scrollV2UiToBottom() {
  const el = document.getElementById("v2-layout-bottom");
  if (!el) return;
  el.scrollIntoView({
    behavior: "instant",
  });
}

/**
 * Captures dom element image and returns a base64 string. add `data-html2canvas-ignore` to any element you want to ignore in the screenshot
 * @param el HTML element
 */
export async function captureDomElementImage(el: HTMLElement) {
  const canvas = await html2Canvas(el, {
    // useCORS: true,
    allowTaint: true,
    foreignObjectRendering: true,
  });
  // maybe compress image
  const width = canvas.width;
  const height = canvas.height;
  // adjust this size for a higher quality
  const max_width = 1600;
  const max_height = 1080;
  if (width > max_width || height > max_height) {
    let xRatio = 1;
    let yRatio = 1;
    if (width > max_width) {
      xRatio = max_width / width;
    }
    if (height > max_height) {
      yRatio = max_height / height;
    }
    const ratio = Math.min(xRatio, yRatio);
    scaleCanvas(canvas, ratio);
  }
  const base64 = canvas.toDataURL("image/png");
  return base64;
}

function scaleCanvas(canvas: HTMLCanvasElement, ratio: number) {
  const tempCanvas = document.createElement("canvas");
  const tempCtx = tempCanvas.getContext("2d");
  const cw = canvas.width;
  const ch = canvas.height;
  tempCanvas.width = cw;
  tempCanvas.height = ch;
  tempCtx?.drawImage(canvas, 0, 0);
  canvas.width *= ratio;
  canvas.height *= ratio;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(tempCanvas, 0, 0, cw, ch, 0, 0, cw * ratio, ch * ratio);
}
