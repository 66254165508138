<template>
  <div
    class="fixed inset-0 z-50 flex justify-end bg-slate-900/70 backdrop-blur-sm overflow-hidden p-2"
    @click.self="jobsStore.closeNotificationPanel()"
  >
    <div
      class="bg-slate-100 dark:bg-slate-900 h-full w-full max-w-lg opacity-0 translate-x-6 animate-[slide-in-right_0.3s_ease-in-out_forwards] relative border border-slate-200/50 dark:border-slate-700/50 rounded-xl overflow-hidden flex flex-col"
    >
      <!-- header -->
      <div
        class="p-3 flex justify-between items-center bg-slate-100 dark:bg-slate-900 border-b dark:border-slate-700/50"
      >
        <p class="text-lg font-semibold">{{ $t("notifications") }}</p>
        <button
          :title="$t('buttons.close')"
          class="flex items-center p-2 bg-slate-200 dark:bg-slate-800 hover:text-red-500 rounded-full"
          @click="emit('close')"
        >
          <XMarkIcon class="h-5 w-5" />
        </button>
      </div>
      <!-- Content -->
      <div class="grow relative">
        <div
          class="absolute inset-0 pb-4 overflow-y-auto space-y-2.5 p-3 dark:divide-slate-700"
        >
          <!-- list -->
          <template v-if="jobsStore.ongoingJobList?.length">
            <SingleRunningJob
              v-for="job in jobsStore.ongoingJobList"
              :key="job.jobId"
              :job="job"
              @remove="dismissRunningJob(job.jobId)"
              @close="emit('close')"
            />
          </template>
          <template v-if="data?.length">
            <NotificationItem
              v-for="job in data"
              :key="job.id"
              :notification="job"
              @close="emit('close')"
            />
          </template>
          <!-- empty -->
          <div
            v-if="!data?.length && !jobsStore.ongoingJobList?.length"
            class="h-2/3 flex flex-col items-center justify-center text-center"
          >
            <div class="p-5 bg-slate-200 dark:bg-slate-50/10 rounded-full">
              <BellIcon class="h-8 w-8 opacity-40" />
            </div>
            <p class="mt-4 text-sm">{{ $t("noNotifications") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SingleRunningJob from "./SingleRunningJob.vue";
import NotificationItem from "./NotificationItem.vue";
import { BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["close"]);

const jobsStore = useJobsStore();
const { data } = useJobListRequest();

function dismissRunningJob(id: string) {
  jobsStore.removeOngoingJob(id);
}
</script>
