<template>
  <div class="h-full flex flex-col justify-center items-center my-auto">
    <!-- Logo -->
    <div
      class="relative h-12 w-12 sm:h-16 sm:w-16 bg-red-400 dark:bg-red-500/50 rounded-full flex items-center justify-center mb-4 sm:mb-8"
    >
      <ChatBubbleOvalLeftIcon
        v-motion
        class="h-5 w-5 sm:h-7 sm:w-7 stroke-[3] text-white"
        :initial="{ opacity: 0 }"
        :enter="{ opacity: 1 }"
        :duration="500"
        :delay="100"
      />

      <ExclamationCircleIcon class="h-5 w-5 absolute right-0 bottom-0 text-slate-50" />

      <div
        v-motion
        class="absolute h-24 w-24 sm:h-32 sm:w-32 rounded-full border-4 border-slate-100/30 dark:border-slate-700/10 opacity-80"
        :initial="{ opacity: 0 }"
        :enter="{ opacity: 0.8 }"
        :duration="500"
        :delay="500"
      />
      <div
        v-motion
        class="absolute h-36 w-36 sm:h-48 sm:w-48 rounded-full border-4 border-slate-100/20 dark:border-slate-700/5"
        :initial="{ opacity: 0 }"
        :enter="{ opacity: 0.6 }"
        :duration="500"
        :delay="1000"
      />
    </div>
    <!-- Content -->
    <div class="flex flex-col items-center relative z-10">
      <h2 class="h2 text-center">
        {{ $t("errors.unknown") }}
      </h2>
      <p class="p max-w-lg text-center">
        {{ $t("errors.tryAgain") }}
      </p>
      <AppButton :label="$t('auth.retry')" @click="emit('retry')" class="w-max mt-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChatBubbleOvalLeftIcon, ExclamationCircleIcon } from "@heroicons/vue/24/solid";

const emit = defineEmits(["retry"]);
</script>
