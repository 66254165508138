<template>
  <div
    class="relative"
    @mouseenter="toggleTooltip(true)"
    @mouseleave="toggleTooltip(false)"
    @focusin="toggleTooltip(true)"
    @focusout="toggleTooltip(false)"
  >
    <button
      class="block"
      :class="triggerWrapperClass"
      aria-haspopup="true"
      :aria-expanded="tooltipOpen"
    >
      <slot name="trigger">
        <svg
          class="w-4 h-4 fill-current text-slate-400 dark:text-slate-500"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
          />
        </svg>
      </slot>
    </button>
    <div class="z-10 absolute" :class="positionOuterClass">
      <transition
        enter-active-class="transition ease-out duration-200 transform"
        enter-from-class="opacity-0 -translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-out duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="tooltipOpen"
          class="rounded border overflow-hidden shadow-lg"
          :class="[colorClass, sizeClass, positionInnerClasses]"
        >
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<!-- EXAMPLE -->
<!-- <Tooltip size="sm" position="left">
  <p>Text to show on hover</p>
  <template #trigger>
    <ItemToHover class="ml-auto h-4 w-4" />
  </template>
</Tooltip> -->

<script setup lang="ts">
const props = defineProps<{
  bg?: "light" | "dark";
  size?: "sm" | "md" | "lg";
  position?: "top" | "right" | "bottom" | "left";
  isOpen?: boolean;
  triggerWrapperClass?: string;
}>();

const [tooltipOpen, toggleTooltip] = useToggle(props.isOpen);

const positionOuterClass = computed(() => {
  switch (props.position) {
    case "right":
      return "left-full top-1/2 -translate-y-1/2";
    case "left":
      return "right-full top-1/2 -translate-y-1/2";
    case "bottom":
      return "top-full left-1/2 -translate-x-1/2";
    default:
      return "bottom-full left-1/2 -translate-x-1/2";
  }
});

const positionInnerClasses = computed(() => {
  switch (props.position) {
    case "right":
      return "ml-2";
    case "left":
      return "mr-2";
    case "bottom":
      return "mt-2";
    default:
      return "mb-2";
  }
});

const colorClass = computed(() => {
  switch (props.bg) {
    case "light":
      return "bg-white text-slate-600 border-slate-200";
    case "dark":
      return "bg-slate-700 text-slate-100 border-slate-600";
    default:
      return "text-slate-600 bg-white dark:bg-slate-700 dark:text-slate-100 border-slate-200 dark:border-slate-600";
  }
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "lg":
      return "p-2.5";
    case "md":
      return "p-2 text-sm";
    case "sm":
      return "p-1 text-xs";
    default:
      return "p-1";
  }
});
</script>
