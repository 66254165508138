const error = `
  <main class="grow h-screen flex flex-col items-center justify-center">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div>
        <div class="max-w-3xl mx-auto text-center">
          <!-- 404 content -->
          <div class="relative inline-flex">
            <div
              class="absolute left-full -mt-4 fill-slate-300 dark:fill-slate-500 hidden lg:block"
              aria-hidden="true"
            >
              <svg width="56" height="43">
                <path
                  d="M4.532 30.45C15.785 23.25 24.457 12.204 29.766.199c.034-.074-.246-.247-.3-.186-4.227 5.033-9.298 9.282-14.372 13.162C10 17.07 4.919 20.61.21 24.639c-1.173 1.005 2.889 6.733 4.322 5.81M18.96 42.198c12.145-4.05 24.12-8.556 36.631-12.365.076-.024.025-.349-.055-.347-6.542.087-13.277.083-19.982.827-6.69.74-13.349 2.24-19.373 5.197-1.53.75 1.252 7.196 2.778 6.688"
                />
              </svg>
            </div>
            <h1 class="h1 font-urbanist text-slate-800 dark:text-slate-50 mb-4">
              Error
            </h1>
          </div>
          <p class="text-xl text-slate-500 dark:text-slate-300">
            There was an error, please try again
          </p>
          <div class="mt-8">
            <button id="retry-btn" class="btn bg-primary hover:bg-primary-600 text-white">Retry</button>
          </div>
        </div>
      </div>
    </div>
  </main>
`;

export function handleInitError() {
  const mountPoint = document.getElementById("app");
  if (!mountPoint) return;
  mountPoint.innerHTML = error;
  const retryBtn = document.getElementById("retry-btn");
  if (!retryBtn) return;
  retryBtn.addEventListener("click", () => {
    window.location.reload();
  });
}
