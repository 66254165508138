import type { AssistantJobSocketData, OngoingJob } from "@/types/jobs";

export function formatTaskCompleteJob(taskId: string) {
  const link = `/task/result/${taskId}`;
  const job: OngoingJob = {
    data: {
      complete: 1,
      error: 0,
      success: 1,
      total: 1,
      created: new Date().toISOString(),
    },
    link,
    jobType: "taskComplete",
    description: ["Your task is complete"],
    jobId: taskId,
    status: "complete",
    title: "Task Complete",
  };
  return job;
}

export function formatWebsiteCrawlComplete(job: AssistantJobSocketData): OngoingJob {
  const data: OngoingJob = {
    jobId: job.jobId,
    title: `All pages processed for website, <br> <a class="text-primary font-medium break-all hover:underline" href="${job.data.websiteUrl}" target="_blank">${job.data.websiteUrl}</a>`,
    description: ["{{complete}} of {{total}} pages complete"],
    status: "complete",
    jobType: "process_website",
    data: {
      complete: job.childrenTotalComplete || 0,
      success: job.childrenTotalComplete || 0,
      error: 0,
      total: job.childrenTotalDispatched || 0,
      created: new Date().toISOString(),
    },
  };
  return data;
}
