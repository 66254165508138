<template>
  <div
    class="w-full p-3 sm:py-4 bg-white dark:bg-slate-800 rounded-xl border border-slate-200/30 dark:border-slate-700/5 relative flex gap-2"
  >
    <NotificationIcon class="h-10 w-10 flex-shrink-0" />

    <div class="grow">
      <div class="grid gap-2 items-center grid-cols-[auto_1fr]">
        <!-- <SpinIcon /> -->
        <p v-html="job.title"></p>
      </div>
      <div class="grid gap-2 items-center grid-cols-[auto_auto_1fr] py-1">
        <div class="h-7 w-1 bg-surface-200 dark:bg-surface-700 rounded-full"></div>
        <SpinIcon v-if="job.status === 'running'" />
        <p class="text-sm ma-text-secondary opacity-80">
          <span
            v-if="typeof descriptions === 'string'"
            class="inline-block sm:block pl-2 sm:pl-0"
          >
            {{ descriptions }}
          </span>
          <template v-else>
            <span
              v-for="(description, i) in descriptions"
              :key="i"
              class="inline-block sm:block pl-2 sm:pl-0"
            >
              {{ description }}
            </span>
          </template>
        </p>
      </div>

      <div v-if="job.link" class="flex gap-3 text-sm py-1">
        <RouterLink
          :to="job.link"
          class="btn py-2 px-3 leading-none bg-primary-500 hover:bg-primary-600 text-white"
          @click="emit('close')"
        >
          {{ $t("wordsOpen") }}
        </RouterLink>
        <button
          class="btn bg-surface-200 dark:bg-surface-700 py-2 px-3 leading-none hover:bg-surface-300 dark:hover:bg-surface-600"
          @click="emit('remove')"
        >
          {{ $t("wordsDismiss") }}
        </button>
      </div>

      <p class="text-sm ma-text-secondary opacity-80 text-right">{{ date }}</p>
    </div>

    <!-- <button class="h-max w-max bg-surface-200 dark:bg-surface-700 rounded-full p-1.5">
      <XMarkIcon class="h-3 w-3 ml-auto flex-shrink-0" @click="emit('remove')" />
    </button> -->
  </div>
</template>

<script setup lang="ts">
import type { OngoingJob } from "@/types/jobs";
import handleBars from "handlebars";
import NotificationIcon from "./NotificationIcon.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import SpinIcon from "../icons/SpinIcon.vue";
import { RouterLink } from "vue-router";

const props = defineProps<{
  job: OngoingJob;
}>();

const emit = defineEmits<{
  (e: "remove"): void;
  (e: "close"): void;
}>();

const descriptions = computed(() => {
  if (!props.job.description) return [];
  if (!props.job.data) return props.job.description;
  if (typeof props.job.description === "string") {
    return props.job.description;
  }
  return props.job.description.map((description: string) => {
    return handleBars.compile(description)(props.job.data);
  });
});

const date = useTimeAgo(props.job.data.created);
</script>
