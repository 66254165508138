export class Stack<T> {
  private readonly _array: T[];
  isEmpty = true;

  constructor(initialData?: T[]) {
    this._array = initialData || [];
    this.setIsEmpty();
  }

  private setIsEmpty(): void {
    this.isEmpty = this._array.length === 0;
  }

  get length(): number {
    return this._array.length;
  }

  peek(): T | null {
    return this._array[this._array.length - 1] || null;
  }

  push(value: T): void {
    this._array.push(value);
    this.setIsEmpty();
  }

  pop(): T | null {
    const data = this._array.pop() || null;
    this.setIsEmpty();
    return data;
  }
}
