<template>
  <div class="h-full overflow-auto">
    <div
      class="min-h-full flex gap-4 flex-col justify-end max-w-2xl p-4 mx-auto relative"
    >
      <div class="h-16 w-full top-0 bg-transparent"></div>
      <template v-if="isRecent">
        <ConversationLoading v-if="loading && !messages?.length" />
        <ConversationError v-else-if="error" @retry="emit('retry')" />
        <template v-else>
          <V2MessageBubble
            v-for="(message, idx) in messages"
            :key="message.id"
            :sender="message.role"
            :message="message.content"
            :is-last="idx === messages.length - 1"
            @ready="scrollToEnd"
          />
        </template>
      </template>
      <template v-else>
        <V2MessageBubble
          v-for="(message, idx) in v2ResponseStore.messages"
          :key="message.sender + idx"
          :sender="message.sender"
          :message="message.statement"
          :is-last="idx === v2ResponseStore.messages.length - 1"
          @ready="scrollToEnd"
        />
      </template>
      <transition
        enter-from-class="opacity-0"
        enter-active-class="delay-[1000ms] duration-0 !absolute"
        enter-to-class="opacity-100"
        leave-active-class="duration-0"
      >
        <V2MessageBubble message="" sender="assistant" v-if="v2InputStore.state.saving">
          <BotBusyAnimation />
        </V2MessageBubble>
      </transition>
      <!-- scroll into view -->
      <div ref="lastElement" class="h-2 w-4"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BotBusyAnimation from "@/components/shared/BotBusyAnimation.vue";
import V2MessageBubble from "./V2MessageBubble.vue";
import type { SingleRecentHeyConversationDetails } from "@/types/v2";
import ConversationLoading from "./ConversationLoading.vue";
import ConversationError from "./meta/ConversationError.vue";

defineProps<{
  isRecent?: boolean;
  messages: SingleRecentHeyConversationDetails["messages"];
  loading?: boolean;
  error?: boolean;
}>();

const emit = defineEmits(["retry"]);

const v2ResponseStore = useV2ResponseStore();
const v2InputStore = useV2InputStore();
const lastElement = ref<HTMLElement | null>(null);

function scrollToEnd() {
  if (!lastElement.value) return;
  lastElement.value.scrollIntoView({ behavior: "auto" });
}
</script>
