<template>
  <div
    class="w-full p-3 sm:py-4 bg-white dark:bg-slate-800 rounded-xl border border-slate-200/30 dark:border-slate-700/5 relative flex gap-2"
  >
    <NotificationIcon
      :notification="notification"
      :is-competitor="isCompetitor"
      class="h-10 w-10 flex-shrink-0"
    />

    <div class="grow">
      <div class="grid gap-2 items-center grid-cols-[auto_1fr]">
        <!-- <SpinIcon /> -->
        <p
          v-html="message"
          :class="{
            'pt-2': notification.type === 'ProcessSocialMedia',
          }"
        ></p>
      </div>

      <div
        v-if="description"
        class="grid gap-2 items-center grid-cols-[auto_auto_1fr] py-1"
      >
        <div class="h-7 w-1 bg-surface-200 dark:bg-surface-700 rounded-full"></div>
        <p class="text-sm ma-text-secondary opacity-80">
          {{ description }}
        </p>
      </div>

      <p class="text-sm ma-text-secondary opacity-80 ml-auto text-right">{{ date }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import NotificationIcon from "./NotificationIcon.vue";
import type { JobNotificationData } from "@/types/jobs";
// import { XMarkIcon } from "@heroicons/vue/24/solid";
import { capitalize } from "lodash";

const props = defineProps<{
  notification: JobNotificationData;
}>();

const { t } = withI18nHelpers();
const date = computed(() => useTimeAgo(props.notification.created));
const isCompetitor = computed(
  () =>
    !!props.notification?.data?.isCompetitor ||
    props.notification.source?.startsWith("account.competitor")
);

const description = computed(() => {
  if (!props.notification) return "";
  if (
    props.notification.type === "crawl_website" &&
    props.notification.childrenTotalComplete &&
    props.notification.childrenTotalDispatched
  ) {
    return t("pagesCrawledDescription", {
      total: props.notification.childrenTotalDispatched,
      complete: props.notification.childrenTotalComplete,
    });
  }
  return "";
});

const message = computed(() => {
  const n = props.notification;
  if (!n) return "";
  switch (n.type) {
    case "process_website":
    case "crawl_website":
      return t(
        isCompetitor.value ? "competitorWebsiteProcessed" : "accountWebsiteProcessed",
        {
          url: `<br/> <a class="text-primary font-medium break-all hover:underline" href="${n.data.websiteUrl}" target="_blank">${n.data.websiteUrl}</a>`,
        }
      );
    case "crawl_website_page":
      return t("accountPageProcessed", {
        url: `<a class="text-primary font-medium break-all hover:underline" href="${n.data.websiteUrl}" target="_blank">${n.data.websiteUrl}</a>`,
      });
    case "ProcessSocialMedia":
      if (n.data?.lookup === "linkedin-company")
        return t(
          isCompetitor.value
            ? "competitorLinkedinCompanyProcessed"
            : "accountLinkedinCompanyProcessed"
        );
      if (n.data?.lookup && n.data.lookup.endsWith("-posts"))
        return t(
          isCompetitor.value
            ? "competitorSocialPostsProcessed"
            : "accountSocialPostsProcessed",
          {
            platform: capitalize(n.data.lookup.replace(/-posts$/, "")),
          }
        );
      // if (n.data.lookup === "twitter-profile")
      //   return "Account Twitter profile discovered";
      if (n.data?.lookup && n.data.lookup.endsWith("-profile"))
        return t(
          isCompetitor.value
            ? "competitorSocialProfileProcessed"
            : "accountSocialProfileProcessed",
          {
            platform: capitalize(n.data.lookup.replace(/-profile$/, "")),
          }
        );
      if (n.data?.accountId && !n.data.lookup)
        return t(
          isCompetitor.value ? "allCompetitorSocialProcessed" : "allSocialProcessed"
        );
      return t("genericSocialProcessed");
    case "unknown":
      return t("socialPostDiscovered");
    default:
      return t("allDataProcessed");
  }
});
</script>
