<template>
  <div
    class="fixed bottom-8 sm:bottom-4 right-4 z-[999] inset-x-4 sm:left-[unset] sm:w-max p-4 rounded-lg sm:rounded-md border dark:border-slate-700/50 bg-white dark:bg-slate-800 shadow-lg"
  >
    <div class="relative">
      <span class="absolute -top-3 -right-3">
        <AppButton text variant="danger" icon="pi pi-times" @click="emit('cancel')" />
      </span>
      <p class="mb-2 sm:mb-4 pr-6 sm:pr-8">{{ $t("pwaUpdateMessage") }}</p>
      <div class="flex justify-end gap-4">
        <AppButton :label="$t('later')" @click="emit('cancel')" />
        <AppButton :label="$t('wordsReload')" @click="emit('confirm')" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/outline";
import AppButton from "../shared/AppButton.vue";

const emit = defineEmits(["confirm", "cancel"]);
</script>
